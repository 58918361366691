"use client";

import Image from "next/image";
import toast from "react-hot-toast";
import { spacing } from "@/utils/spacing";
import { useEffect, useState } from "react";
import { themeColors } from "@/utils/themeColors";
import { borderRadius } from "@/utils/borderRadius";
import { ASSETS_URL } from "@/config/deployConstants";
import { useRouter } from "next13-progressbar";
import { LocalAuthUser } from "@knowt/syncing/hooks/user/types";
import BasicUserInfoForm from "./components/BasicUserInfoForm";
import UserFeedbackPrompt from "./components/UserFeedbackPrompt";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { InnerContainer, MainContainer } from "@/components/Containers";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { redirectAfterLoginIfNeeded } from "@/app/page/rootComponents/AuthHubListener";
import { getInitialSetupUrl } from "./utils";
import br from "@/styles/breakpoints.module.css";

const KaiWaiving = `${ASSETS_URL}/images/kai-waving.svg`;

export const useCheckToDisplayInitialAccountSetupPage = () => {
    const router = useRouter();

    const { user, loginInProgress } = useCurrentUser();

    useEffect(() => {
        const asyncEffect = async () => {
            if (loginInProgress || !user) return;

            const url = await getInitialSetupUrl({ user });

            if (url) {
                router.replace(url);
            }
        };

        asyncEffect();

        // we only want this to check once on page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, loginInProgress]);

    return undefined;
};

type InitialTabIndexKey = "REFERRAL" | "GOAL";

const InitialSetupPage = ({
    serverUser,
    initialTab,
}: {
    serverUser: LocalAuthUser;
    initialTab?: InitialTabIndexKey | undefined;
}) => {
    const initialTabIndex: {
        [key in InitialTabIndexKey]: number;
    } = {
        REFERRAL: 1,
        GOAL: 2,
    };

    const defaultIndex = initialTab ? initialTabIndex[initialTab] : 0;
    const [contentIndex, setContentIndex] = useState(defaultIndex);

    const setupContent = [
        {
            key: 1,
            renderComponent: () => <BasicUserInfoForm onNext={() => showNextContent()} serverUser={serverUser} />,
        },
        {
            key: 2,
            renderComponent: () => <UserFeedbackPrompt variant={"REFERRAL"} onNext={() => showNextContent()} />,
        },
        {
            key: 3,
            renderComponent: () => <UserFeedbackPrompt variant={"GOAL"} onNext={() => showNextContent()} />,
        },
    ];

    const showNextContent = () => {
        if (contentIndex < setupContent.length - 1) {
            setContentIndex(contentIndex + 1);
        } else {
            toast.success("Your account is all set up!");
            redirectAfterLoginIfNeeded();
        }
    };

    return (
        <MainContainer style={{ paddingBlock: "0rem 0rem", minHeight: "calc(99vh - var(--navbar-height))" }}>
            <InnerContainer
                data-testid="inital-setup-page"
                style={{
                    flex: "unset",
                    paddingTop: spacing.XL,
                }}>
                <FlexRowAlignCenter style={{ gap: "8rem" }}>
                    <FlexColumn
                        style={{
                            backgroundColor: themeColors.neutralWhite,
                            padding: `${spacing.XL} ${spacing.LG}`,
                            borderRadius: borderRadius.card,
                            maxWidth: "74.8rem",
                        }}>
                        {setupContent[contentIndex].renderComponent()}
                    </FlexColumn>
                    <Image
                        className={br.mdDownDisplayNone}
                        src={KaiWaiving}
                        alt="Kai waiving"
                        width={208}
                        height={261}
                    />
                </FlexRowAlignCenter>
            </InnerContainer>
        </MainContainer>
    );
};

export default InitialSetupPage;
