import { StaticDatePickerProps } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export type DateInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> & {
    DatePickerProps?: Omit<
        StaticDatePickerProps<dayjs.Dayjs>,
        "isOpen" | "onClose" | "defaultValue" | "value" | "onChange"
    >;
} & { value: Date | null; onChange: (date: dayjs.Dayjs) => void };

export const DATE_STR_FORMAT = "MM/DD/YYYY";
