"use client";

import React, { useEffect } from "react";
import GuidedWalkthrough, { useGuidedWalkthrough, WALKTHROUGH_LABELS } from "@/features/GuidedWalkthrough";
import { useClassManagementContextSelector } from "../../hooks/useClassManagementContext";

export const WALKTHROUGH_INVITE_BTN = "walkthrough-invite-btn";
export const WALKTHROUGH_ADD_FILE_BTN = "walkthrough-add-file-btn";
export const WALKTHROUGH_SECTION_PILL = "walkthrough-section-pill";
export const WALKTHROUGH_CLASS_TAB = "walkthrough-class-tab";

export const ClassDashboardWalkthrough = () => {
    const course = useClassManagementContextSelector(s => s.course);

    const sections = course.sections;
    const hasSection = sections.length > 0;

    const numOfSteps = hasSection ? 4 : 3;

    const {
        stepIdx,
        nextAStep,
        backAStep,
        isWalkthroughActive,
        activateWalkthrough,
        hasWalkthroughTaken,
        doneWalkthrough,
        doneAStep,
    } = useGuidedWalkthrough({ label: WALKTHROUGH_LABELS.CLASS_DASHBOARD_INTRO });

    useEffect(() => {
        if (hasWalkthroughTaken()) return;
        activateWalkthrough({});
    }, [hasWalkthroughTaken, activateWalkthrough]);

    return (
        <GuidedWalkthrough
            onFinish={() => doneWalkthrough()}
            isActive={isWalkthroughActive}
            stepIndex={stepIdx}
            onNext={nextAStep}
            onBack={backAStep}
            steps={[
                {
                    title: "Invite students to your class",
                    content:
                        "Students with an existing Knowt account can input a class code to join, or share an invitation link for anyone to use.",
                    placement: "right-end",
                    target: "." + WALKTHROUGH_INVITE_BTN,
                    onClick: () => doneAStep(stepIdx),
                    numOfSteps,
                },

                {
                    title: "Add new files",
                    content: "Add a flashcard or note to your classroom here.",
                    placement: "right-end",
                    target: "." + WALKTHROUGH_ADD_FILE_BTN,
                    onClick: () => doneAStep(stepIdx),
                    numOfSteps,
                },
                {
                    title: "Filter what files are visible to each section",
                    content: "You’ll see all the files in the classroom by default.",
                    placement: "right-end",
                    target: "." + WALKTHROUGH_SECTION_PILL,
                    numOfSteps,
                },
                {
                    title: "Switch to view students in your class",
                    content:
                        "All files will be visible on your Dashboard, but information on your students can be found in Students.",
                    placement: "right-end",
                    target: "." + WALKTHROUGH_CLASS_TAB,
                    onClick: () => doneWalkthrough(),
                    numOfSteps,
                },
            ]}
        />
    );
};
