import packageInfo from "@/package.json";
import * as Sentry from "@sentry/nextjs";

export const log = (key, ...extras) => {
    if (__DEV__ || packageInfo.development) {
        // eslint-disable-next-line no-console
        console.log(key, ...extras);
    }
};

export const forceLog = (key, ...extras) => {
    // eslint-disable-next-line no-console
    console.log(key, ...extras);
};

export const warn = (key, ...extras) => {
    if (__DEV__ || packageInfo.development) {
        // eslint-disable-next-line no-console
        console.warn(key, ...extras);
    }
};

export const forceWarn = (key, ...extras) => {
    // eslint-disable-next-line no-console
    console.warn(key, ...extras);
};

export const report = (error: unknown, location: string, input?: { [key: string]: unknown }) => {
    if (__DEV__ || packageInfo.development) {
        // eslint-disable-next-line no-console
        console.error("ERROR", location, error, input);
    }

    // TODO: we're in the dark about errors! We need to set up Sentry to report errors to us.
    Sentry.withScope(scope => {
        scope.setTag("error_location", location);
        if (input) scope.setExtra("input", input);
        scope.setExtra("error", error);
        Sentry.captureException(error);
    });
};

export const onAppsyncError = ({ status }) => {
    if (status === 405) {
        // captcha
    }
};
