import React from "react";
import clsx from "clsx";
import styles from "./clickableText.module.css";

type ClickableTextProps = React.ComponentPropsWithoutRef<"button"> & {
    style?: React.CSSProperties;
};

// NOTE: should be used as a button wrapper, not as anchor tag.
const ClickableText = ({ children, className, style, ...buttonProps }: ClickableTextProps) => {
    return (
        <button
            className={clsx(styles.clickableText, "strippedBtn", "bold", className)}
            style={{ ...style }}
            {...buttonProps}>
            {children}
        </button>
    );
};

export default ClickableText;
