import { FlexRowAlignCenter } from "@/components/Flex";
import { iconSizes } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import dayjs from "dayjs";
import { ChevronDown } from "lucide-react";
import { useState } from "react";
import DatePicker from "../DatePicker";
import { DateInputProps, DATE_STR_FORMAT } from "../types";

const ChevronDownIcon = ({ onClick }: { onClick: () => void }) => (
    <ChevronDown
        size={iconSizes.SM}
        fill={themeColors.neutralBlack}
        strokeWidth={0}
        style={{ position: "absolute", right: "1rem", bottom: "50%", transform: "translateY(50%)", cursor: "pointer" }}
        onClick={onClick}
    />
);

const BorderedInputDatePicker = ({ value, style, DatePickerProps, onChange, ...props }: DateInputProps) => {
    const defaultValue = value ? dayjs(value) : dayjs();
    const [inputVal, setInputVal] = useState(value ? dayjs(value).format(DATE_STR_FORMAT) : "");
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <FlexRowAlignCenter style={{ position: "relative" }}>
                <input
                    {...props}
                    onFocus={e => e.target.blur()} // user must select the date from Date Picker Dialog
                    onClick={() => setIsOpen(true)}
                    value={inputVal}
                    readOnly
                    style={{
                        outline: "none",
                        cursor: "pointer",
                        font: "600 1.5rem var(--knowt-font-name)",
                        height: "4.8rem",
                        padding: "0 2.4rem",
                        textAlign: "center",
                        borderRadius: "999px",
                        backgroundColor: props.disabled ? themeColors.neutral1 : "transparent",
                        border: `1.5px solid ${themeColors.neutralBlack}`,
                        flex: 1,
                        color: themeColors.neutralBlack,
                        ...style,
                    }}
                />
                {!props.disabled && <ChevronDownIcon onClick={() => setIsOpen(true)} />}
            </FlexRowAlignCenter>
            <DatePicker
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                defaultValue={defaultValue}
                value={inputVal ? dayjs(inputVal, DATE_STR_FORMAT) : defaultValue}
                onAccept={d => {
                    if (!d) return;
                    setInputVal(d.format(DATE_STR_FORMAT));
                    onChange(d);
                }}
                {...DatePickerProps}
            />
        </>
    );
};

export default BorderedInputDatePicker;
