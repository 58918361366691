import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import noop from "@knowt/syncing/utils/noop";
import { useCallback } from "react";
import { OpenAuthPopupProps, useAuthPopupContextSelector } from "@/features/Auth";

export const useCheckAuth = () => {
    const openAuthPopup = useAuthPopupContextSelector(state => state.openAuthPopup);
    const { loginInProgress, user } = useCurrentUser();

    const checkAuth = useCallback(
        (onSignAction = noop, openAuthPopupProps: OpenAuthPopupProps, onCancel = noop) => {
            if (loginInProgress) {
                setTimeout(() => checkAuth(onSignAction, openAuthPopupProps, onCancel), 1000);
            } else if (!user) {
                onCancel?.();
                openAuthPopup(openAuthPopupProps);
            } else {
                onSignAction();
            }
        },
        [loginInProgress, openAuthPopup, user]
    );

    return { checkAuth };
};
