"use client";

import React, { useEffect, useState } from "react";
import GuidedWalkthrough, {
    checkLSHasWalkthroughTaken,
    useGuidedWalkthrough,
    WALKTHROUGH_LABELS,
    removeLSWalkthroughTaken,
} from "@/features/GuidedWalkthrough";
import { wait } from "@knowt/syncing/utils/genericUtils";

export const WALKTRHOUGH_BEGIN_CREATE_BTN = "walkthrough-create-new-content-btn-pill";
export const WALKTHROUGH_HOME_TAB_SWITCHER = "walkthrough-home-tab-switcher";
export const WALKTRHOUGH_CREATE_PDF_BTN = "walkthrough-create-pdf-btn-option";

export const CreateNoteGuidedWalkthrough = ({ userHasNoContent }: { userHasNoContent: boolean }) => {
    const { isWalkthroughActive, activateWalkthrough, hasWalkthroughTaken, doneWalkthrough } = useGuidedWalkthrough({
        label: WALKTHROUGH_LABELS.BEGIN_CREATE,
    });

    useEffect(() => {
        if (hasWalkthroughTaken()) return;
        if (!userHasNoContent) return;

        activateWalkthrough({});
    }, [userHasNoContent, hasWalkthroughTaken, activateWalkthrough]);

    return (
        <GuidedWalkthrough
            onFinish={() => doneWalkthrough()}
            isActive={isWalkthroughActive}
            steps={[
                {
                    title: "Create new files here",
                    content:
                        "You can create notes and flashcards from scratch or with the help of AI! If you have an existing Quizlet set, you can import it over as well.",
                    placement: "right-end",
                    target: "." + WALKTRHOUGH_BEGIN_CREATE_BTN,
                    onClick: () => doneWalkthrough(),
                },
            ]}
        />
    );
};

export const CreatePDFGuidedWalkthrough = () => {
    const [stepIdx, setStepIdx] = useState(0);

    const { isWalkthroughActive, activateWalkthrough, hasWalkthroughTaken, doneWalkthrough } = useGuidedWalkthrough({
        label: WALKTHROUGH_LABELS.CREATE_PDF,
    });

    useEffect(() => {
        if (hasWalkthroughTaken()) return;

        const walkthroughBeginCreateTaken = checkLSHasWalkthroughTaken({ label: WALKTHROUGH_LABELS.BEGIN_CREATE });

        const walkthroughCreateNoteTaken = checkLSHasWalkthroughTaken({
            label: WALKTHROUGH_LABELS.CHOOSE_NOTE_TEMPLATE,
        });

        const walkthroughMediaUploadTaken = checkLSHasWalkthroughTaken({
            label: WALKTHROUGH_LABELS.MEDIA_UPLOAD_PROGRESS,
        });

        if (!walkthroughBeginCreateTaken) return;
        if (!walkthroughCreateNoteTaken) return;
        if (walkthroughMediaUploadTaken) return;

        activateWalkthrough({});
    }, [hasWalkthroughTaken, activateWalkthrough]);

    return (
        <GuidedWalkthrough
            onFinish={() => doneWalkthrough()}
            isActive={isWalkthroughActive}
            stepIndex={stepIdx}
            steps={[
                {
                    title: "Try uploading a PDF to get notes/flashcards instantly",
                    content: "It only takes a few seconds to get started.",
                    placement: "bottom-start",
                    target: "." + WALKTRHOUGH_BEGIN_CREATE_BTN,
                    onClick: () => {
                        setTimeout(() => setStepIdx(1), 200);
                    },
                },
                {
                    title: "Summarize PDFs into notes and flashcards with AI",
                    content:
                        "Convert long lecture slides into flashcards you can study from or condense them down into a shorter note. ",
                    placement: "right",
                    target: "." + WALKTRHOUGH_CREATE_PDF_BTN,
                    disableScrolling: true,
                    onClick: () => doneWalkthrough(),
                },
            ]}
            styles={{ options: { zIndex: 1001 } }}
        />
    );
};

export const CreateClassGuidedWalkthrough = () => {
    const { isWalkthroughActive, activateWalkthrough, hasWalkthroughTaken, doneWalkthrough } = useGuidedWalkthrough({
        label: WALKTHROUGH_LABELS.CLASS_CREATE_NEW,
    });

    useEffect(() => {
        if (hasWalkthroughTaken()) return;
        activateWalkthrough({});
    }, [hasWalkthroughTaken, activateWalkthrough, doneWalkthrough]);

    return (
        <GuidedWalkthrough
            onFinish={() => {
                removeLSWalkthroughTaken({ label: WALKTHROUGH_LABELS.CLASS_CREATE_NEW });
                removeLSWalkthroughTaken({ label: WALKTHROUGH_LABELS.CLASS_SWITCH_FILES_TAB });
            }}
            isActive={isWalkthroughActive}
            steps={[
                {
                    title: "Create your first class",
                    content: "You'll be able to access all your classes from this home screen.",
                    placement: "right-end",
                    target: "." + WALKTRHOUGH_BEGIN_CREATE_BTN,
                    numOfSteps: 2,
                    onClick: () => doneWalkthrough(),
                },
                {
                    title: "Switch to see your personal files",
                    content: 'Want to create files that aren’t ready to add to a class yet? Switch over to "My Files."',
                    placement: "right-end",
                    target: "." + WALKTHROUGH_HOME_TAB_SWITCHER,
                    numOfSteps: 2,
                },
            ]}
        />
    );
};

export const SwitchFilesTabGuidedWalkthrough = () => {
    const [stepIndex, setStepIndex] = useState(1);

    const { isWalkthroughActive, activateWalkthrough, hasWalkthroughTaken, doneWalkthrough } = useGuidedWalkthrough({
        label: WALKTHROUGH_LABELS.CLASS_SWITCH_FILES_TAB,
    });

    useEffect(() => {
        const shouldRunWalkthrough = async () => {
            await wait(500);

            if (hasWalkthroughTaken()) return;

            if (checkLSHasWalkthroughTaken({ label: WALKTHROUGH_LABELS.CLASS_CREATE_NEW })) {
                activateWalkthrough({});
            }
        };

        shouldRunWalkthrough();
    }, [hasWalkthroughTaken, activateWalkthrough, doneWalkthrough]);

    return (
        <GuidedWalkthrough
            stepIndex={stepIndex}
            onBack={() => setStepIndex(stepIndex - 1)}
            onNext={() => setStepIndex(stepIndex + 1)}
            onFinish={() => doneWalkthrough()}
            isActive={isWalkthroughActive}
            steps={[
                {
                    title: "Create your first class",
                    content: "You'll be able to access all your classes from this home screen.",
                    placement: "right-end",
                    target: "." + WALKTRHOUGH_BEGIN_CREATE_BTN,
                    numOfSteps: 2,
                },
                {
                    title: "Switch to see your personal files",
                    content: 'Want to create files that aren’t ready to add to a class yet? Switch over to "My Files."',
                    placement: "right-end",
                    target: "." + WALKTHROUGH_HOME_TAB_SWITCHER,
                    numOfSteps: 2,
                },
            ]}
        />
    );
};
