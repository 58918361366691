"use client";

import React, { useMemo } from "react";
import { themeColors } from "@/utils/themeColors";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, StylesOptions } from "react-joyride";
import DelayedComponent from "@/components/DelayedComponent";
import Portal from "@mui/base/Portal";
import WalkthroughTooltip from "./components/WalkthroughTooltip";
import { WalkthroughStep } from "./types";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";

type GuidedWalkthroughProps = {
    steps: WalkthroughStep[];
    isActive: boolean;
    /** Control the step index manually */
    stepIndex?: number;
    /** Additional callback to call on steps finished in case we want to do something. Pressing X will trigger this callback */
    onFinish?: () => void;
    /** In case `stepIndex` is being controlled manually, next button must be handled manually */
    onNext?: () => void;
    /** In case `stepIndex` is being controlled manually, back button must be handled manually */
    onBack?: () => void;
    disableScrollParentFix?: boolean;
    styles?: { options?: Partial<StylesOptions>; overlay?: React.CSSProperties };
};

const GuidedWalkthrough = ({
    steps: steps_,
    isActive,
    stepIndex,
    onFinish,
    onNext,
    onBack,
    disableScrollParentFix = false,
    styles = {},
}: GuidedWalkthroughProps) => {
    const { smDown } = useBreakPoints();

    // merge each step with our default configuration
    const steps = useMemo(() => {
        return steps_.map(step => {
            if (step.onClick && typeof window !== "undefined") {
                const className = step.target.replace(".", "");
                const el = document.getElementsByClassName(className)[0] as HTMLElement;
                if (el) el.onclick = e => step.onClick?.(e);
            }

            return {
                disableBeacon: true,
                spotlightClicks: true,
                onNext: onNext ? () => onNext() : undefined,
                ...step,
            };
        });
    }, [onNext, steps_]);

    const handleJoyrideCallback = (data: CallBackProps) => {
        // const { action, index, status, type } = data;
        const { status, type, action } = data;

        if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
            onFinish?.();
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            if (action === ACTIONS.PREV) {
                onBack?.();
            }
        }
    };

    if (smDown) {
        return null;
    }

    return (
        <Portal>
            {/* NOTE: we have to add delay component to fix hydration error */}
            <DelayedComponent delay={50}>
                <Joyride
                    callback={handleJoyrideCallback}
                    continuous
                    run={isActive}
                    scrollToFirstStep
                    showProgress
                    showSkipButton
                    tooltipComponent={WalkthroughTooltip}
                    scrollOffset={300}
                    steps={steps}
                    stepIndex={stepIndex}
                    styles={{
                        options: { zIndex: 850, arrowColor: themeColors.neutral3, ...styles?.options },
                        overlay: { backgroundColor: "rgba(0, 0, 0, 0.0)", ...styles?.overlay },
                    }}
                    disableScrollParentFix={disableScrollParentFix}
                />
            </DelayedComponent>
        </Portal>
    );
};

export default GuidedWalkthrough;
