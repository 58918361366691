import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { Class } from "@/graphql/schema";
import { callGetClass, callListClasses, callListEnrolledClasses } from "@/hooks/classes/graphqlUtils";
import { resolveClassSWRKey, resolveEnrolledClassesSWRKey, resolveTeacherClassesSWRKey } from "@/hooks/classes/utils";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { populateCacheWithFallbackData } from "@/hooks/swr";

export const useClasses = ({
    isEnabled = true,
    fallbackData,
}: { isEnabled?: boolean; fallbackData?: Record<string, Class> } = {}) => {
    const { isTeacher } = useCurrentUser();

    const {
        teacherClasses,
        isLoading: isLoadingTeacherClasses,
        mutate: mutateTeacher,
    } = useTeacherClasses({
        isEnabled: isTeacher && isEnabled,
        fallbackData,
    });

    const {
        enrolledClasses,
        isLoading: isLoadingStudentClasses,
        mutate: mutateStudent,
    } = useEnrolledClasses({
        isEnabled: !isTeacher && isEnabled,
        fallbackData,
    });

    const mutate = isTeacher ? mutateTeacher : mutateStudent;

    const classes = isTeacher ? teacherClasses : enrolledClasses;

    return { classes, isLoading: isLoadingTeacherClasses || isLoadingStudentClasses, mutate };
};

const useTeacherClasses = ({
    isEnabled = true,
    fallbackData,
}: { isEnabled?: boolean; fallbackData?: Record<string, Class> } = {}) => {
    const { userId } = useCurrentUser();

    const {
        data: teacherClasses,
        error,
        mutate,
    } = useSWRImmutable(
        resolveTeacherClassesSWRKey({
            userId,
            isEnabled,
        }),
        () => callListClasses({}),
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    return { teacherClasses, error, isLoading: isEnabled && !error && !teacherClasses, mutate };
};

const useEnrolledClasses = ({
    isEnabled = true,
    fallbackData,
}: { isEnabled?: boolean; fallbackData?: Record<string, Class> } = {}) => {
    const { userId } = useCurrentUser();

    const {
        data: enrolledClasses,
        error,
        mutate,
    } = useSWRImmutable(resolveEnrolledClassesSWRKey({ userId, isEnabled }), () => callListEnrolledClasses({}), {
        fallbackData,
        use: [populateCacheWithFallbackData],
    });

    return { enrolledClasses, error, isLoading: isEnabled && !error && !enrolledClasses, mutate };
};

export const useClass = ({
    classId,
    fallbackData = undefined,
    isEnabled = true,
}: {
    classId?: string | null | undefined;
    fallbackData?: Class | undefined;
    isEnabled?: boolean;
}) => {
    const { userId } = useCurrentUser();

    const {
        data: course,
        error,
        mutate,
    } = useSWRImmutable(
        isEnabled && resolveClassSWRKey({ classId, userId, isEnabled: !!classId }),
        () => callGetClass({ classId }),
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    return { course: classId === null ? null : (course as Class), isLoading: !course && !error, mutate };
};
