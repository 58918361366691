"use client";

import {
    ComponentType,
    Context as ContextOrig,
    createContext as createContextOrig,
    ReactNode,
    useContext as useContextOrig,
    useEffect,
    useRef,
    useSyncExternalStore,
} from "react";

type ContextValue<Value> = {
    value: Value;
    subscribe: (listener: () => void) => () => void;
    notify: () => void;
};

type Context<Value> = {
    Provider: ComponentType<{ value: Value; children: ReactNode }>;
    displayName?: string;
};

const initStore = <Value,>(value: Value): ContextValue<Value> => {
    const listeners = new Set<() => void>();
    return {
        value,
        subscribe: (listener: () => void) => {
            listeners.add(listener);
            return () => listeners.delete(listener);
        },
        notify: () => listeners.forEach(listener => listener()),
    };
};

export const createContext = <Value,>(defaultValue: Value) => {
    const context = createContextOrig(initStore(defaultValue));
    const ProviderOrig = context.Provider;

    const Provider = ({ value, children }) => {
        const storeRef = useRef(initStore(value));
        const store = storeRef.current;
        useEffect(() => {
            if (!Object.is(store.value, value)) {
                store.value = value;
                store.notify();
            }
        });
        return <ProviderOrig value={store}>{children}</ProviderOrig>;
    };

    // eslint-disable-next-line
    // @ts-ignore
    context.Provider = Provider;

    // no support for Consumer -- just copying what's in "use-context-selector" library
    // https://github.com/dai-shi/use-context-selector/blob/73ac3ecabdb4aa16f8486f0280df4b5f7cb99410/src/index.ts#L144
    delete context.Consumer;

    return context as unknown as Context<Value>;
};

export const useContextSelector = <Value, Selected>(context: Context<Value>, selector: (value: Value) => Selected) => {
    const store = useContextOrig(context as unknown as ContextOrig<ContextValue<Value>>);
    return useSyncExternalStore(
        store?.subscribe,
        () => selector(store?.value),
        () => selector(store?.value)
    );
};

/**
 * This hook returns the entire context value.
 * Use this instead of React.useContext for consistent behavior.
 */
export const useContext = <Value,>(context: Context<Value>) => useContextSelector(context, value => value);
