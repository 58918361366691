import { TIME_SECONDS } from "@knowt/syncing/utils/dateTimeUtils";
import { safeParse } from "@knowt/syncing/utils/stringUtils";
import { useState } from "react";
import { useCookie } from "react-use";

const useCookieWithFallback = <T>({
    key,
    fallback,
    override: _override,
    options = {
        expires: TIME_SECONDS.YEAR,
        path: "/",
        secure: true,
    },
}: {
    key: string;
    fallback: T;
    override?: T | null | undefined;
    options?: Cookies.CookieAttributes;
}) => {
    const [override, setOverride] = useState<T | null | undefined>(_override);
    const [cookie, _updateCookie] = useCookie(key);

    const updateCookie = (newValue: T) => {
        setOverride(undefined);
        const val = typeof newValue === "object" ? JSON.stringify(newValue) : String(newValue);
        _updateCookie(val, options);
    };

    const valueType = typeof fallback;

    const value =
        override ??
        (valueType === "object"
            ? safeParse(cookie)
            : valueType === "boolean"
            ? cookie === "true"
            : valueType === "number"
            ? Number(cookie)
            : cookie) ??
        fallback;

    return [value as T, updateCookie] as const;
};

export default useCookieWithFallback;
