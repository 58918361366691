import {
    Class,
    FlashcardSet,
    Folder,
    Media,
    Note,
    StudySessionProgressEnum,
    UserDetails,
} from "@knowt/syncing/graphql/schema";
import { useParams, useSearchParams } from "next/navigation";
import React, { useState } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import { useClass } from "@knowt/syncing/hooks/classes/useClass";
import { UserContentManagementContextProvider } from "../../hooks/useUserContentManagementContext";
import { getMostRecentClassFlashcardSet } from "@knowt/syncing/hooks/flashcards/utils";
import useCookieWithFallback from "@/hooks/useCookieWithFallback";
import { STORAGE_KEYS } from "@/config/constants";
import { UserContentSortSettings } from "../../types";
import { useSafeQueryNavigation } from "@/hooks/navigation/useSafeQueryNavigation";
import { ClassDashboardTab, ClassFilesTab } from "../types";

type ClassManagementContextProps = {
    //
    course: Class;
    members: ClassMembersWithDetails[];
    classId: string;
    //
    isStudent: boolean;
    isTeacher: boolean;
    readOnly: boolean;
    //
    currentTab: ClassDashboardTab;
    changeCurrentTab: (newTab: ClassDashboardTab) => void;
    currentFilesTab: ClassFilesTab;
    setCurrentFilesTab: (newTab: ClassFilesTab) => void;
    //
    selectedFlashcardSet: FlashcardSet | undefined;
    setSelectedFlashcardSetId: (id: string | null) => void;
    selectedSection: string | null;
    setSelectedSection: (sectionId: string | null) => void;
    studyProgressFilter: StudySessionProgressEnum | null;
    setStudyProgressFilter: (filter: StudySessionProgressEnum | null) => void;
};

const ClassManagementContext = createContext<ClassManagementContextProps | null>(null);

export const ClassManagementContextProvider = ({
    children,
    serverClass,
    serverUser,
    serverFolders,
    serverNotes,
    serverFlashcardSets,
    serverMedias,
    classTab,
    sortValue,
}: {
    children: React.ReactNode;
    serverClass: Class;
    serverUser: UserDetails;
    serverFolders: Record<string, Folder>;
    serverNotes: Record<string, Note>;
    serverFlashcardSets: Record<string, FlashcardSet>;
    serverMedias: Record<string, Media>;
    classTab: ClassDashboardTab;
    sortValue: UserContentSortSettings;
}) => {
    const searchParams = useSearchParams();

    const userId = serverUser.ID as string;
    const { classId } = useParams() as { classId: string };
    const readOnly = userId !== serverClass.userId;

    const { course: swrCourse } = useClass({ classId: classId, fallbackData: serverClass });
    const course = swrCourse ?? serverClass;

    const isStudent = serverUser.accountType === "Student" && !serverUser.classes?.enrolled?.length;
    const isTeacher = serverUser.accountType === "Teacher";
    const tabFromParams = searchParams.get("tab") as ClassDashboardTab | null;
    const setFromParams = searchParams.get("set") as string | null;
    const progressFilterFromParams = searchParams.get("progress") as StudySessionProgressEnum | null;

    const [currentTab, setCurrentTab] = useCookieWithFallback<ClassDashboardTab>({
        key: STORAGE_KEYS.CLASSES_PAGE_TAB(classId),
        fallback: classTab,
        override: tabFromParams,
    });

    const [currentFilesTab, setCurrentFilesTab] = useState<ClassFilesTab>(ClassFilesTab.HOME);

    const { addParamsReplace } = useSafeQueryNavigation();

    const [selectedFlashcardSetId, setSelectedFlashcardSetId] = useCookieWithFallback({
        key: STORAGE_KEYS.CLASSES_PAGE_PROGRESS_FLASHCARD_SET_ID(classId),
        fallback: getMostRecentClassFlashcardSet(serverFlashcardSets)?.flashcardSetId ?? null,
        override: setFromParams,
    });

    const selectedFlashcardSet = selectedFlashcardSetId
        ? serverFlashcardSets[selectedFlashcardSetId]
        : Object.values(serverFlashcardSets).find(set => !set.draft);

    const changeCurrentTab = (newTab: ClassDashboardTab) => {
        setCurrentTab(newTab);

        addParamsReplace({ tab: newTab });
    };

    const [selectedSection, setSelectedSection] = useState<string | null>(null);

    const [studyProgressFilter, setStudyProgressFilter] = useState<StudySessionProgressEnum | null>(
        progressFilterFromParams ?? null
    );

    return (
        <ClassManagementContext.Provider
            value={{
                course,
                classId,
                readOnly,
                isStudent,
                isTeacher,
                currentTab,
                changeCurrentTab,
                currentFilesTab,
                setCurrentFilesTab,
                selectedFlashcardSet,
                setSelectedFlashcardSetId,
                selectedSection,
                setSelectedSection,
                studyProgressFilter,
                setStudyProgressFilter,
            }}>
            <UserContentManagementContextProvider
                serverFolders={serverFolders}
                serverNotes={serverNotes}
                serverFlashcardSets={serverFlashcardSets}
                serverUser={serverUser}
                serverMedias={serverMedias}
                sortValue={sortValue}
                classId={classId}>
                {children}
            </UserContentManagementContextProvider>
        </ClassManagementContext.Provider>
    );
};

export const useClassManagementContextSelector = <T,>(selector: (state: ClassManagementContextProps) => T) =>
    useContextSelector(ClassManagementContext, selector);
