import { fetchPostJSON } from "@knowt/syncing/fetchFunctions/fetchWrappers";
import { createTheme } from "@mui/material/styles";
import { useCallback, useEffect, useState } from "react";
import Mixpanel from "./analytics/Mixpanel";

export const HTML_FONT_SIZE = 10;
export type Theme = "light" | "dark";
export type SelectedTheme = Theme | "auto";
export const THEME_KEY = "theme";
export const IS_SYSTEM_THEME_KEY = "isSystemTheme";

const theme = (additionalThemeOptions = {}) =>
    createTheme({
        typography: {
            // Tell Material-UI what's the font-size on the html element is.
            htmlFontSize: HTML_FONT_SIZE,
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 900,
                lg: 1200,
                xl: 1536,
            },
        },
        ...additionalThemeOptions,
    });

export const useTheme = () => {
    // in case we are on the server side, and we need a theme in the js code, we can use this
    const getInitialThemeKey = useCallback(() => {
        if (typeof window !== "undefined" && window.matchMedia("(prefers-color-scheme: dark)").matches) {
            return "dark";
        }
        return "light";
    }, []);

    const [isSystemTheme, setIsSystemTheme] = useState(false);
    const [theme, setTheme] = useState<Theme>(getInitialThemeKey());

    const updateThemeState = useCallback(async (newTheme: Theme, store = true) => {
        setTheme(newTheme);
        document.documentElement.setAttribute("data-theme", newTheme);

        if (store) {
            localStorage.setItem(THEME_KEY, newTheme);
            fetchPostJSON("/api/cookies/theme", { theme: newTheme });
        }
    }, []);

    useEffect(() => {
        const isSystemTheme = localStorage.getItem(IS_SYSTEM_THEME_KEY) === "true";
        const theme = localStorage.getItem(THEME_KEY) as Theme | null;
        setIsSystemTheme(isSystemTheme);
        // only update the theme if its not already stored
        updateThemeState(theme && !isSystemTheme ? theme : getInitialThemeKey(), !theme);
    }, [getInitialThemeKey, updateThemeState]);

    const changeTheme = useCallback(
        async (newTheme: SelectedTheme) => {
            Mixpanel.track(`Theme Changed`, { theme: newTheme });

            if (newTheme === "auto") {
                // SYSTEM THEME
                localStorage.removeItem(THEME_KEY);
                localStorage.setItem(IS_SYSTEM_THEME_KEY, "true");
                setIsSystemTheme(true);
                return updateThemeState(getInitialThemeKey());
            }

            await updateThemeState(newTheme);
            setIsSystemTheme(false);
            localStorage.removeItem(IS_SYSTEM_THEME_KEY);
        },
        [updateThemeState, getInitialThemeKey]
    );

    return {
        theme,
        selectedTheme: isSystemTheme ? "auto" : theme,
        isDarkMode: theme === "dark",
        isLightTheme: theme === "light",
        isSystemTheme,
        changeTheme,
    };
};

export default theme;
