import { QueryString } from "@/types/common";
import { TITLE_FILLER, UNTITLED } from "@knowt/syncing/utils/dataCleaning";
import { createQueryStrings } from "@knowt/syncing/utils/genericUtils";
import { cleanTitle } from "@knowt/syncing/utils/stringUtils";

export const getQueryStringByName = (url: string, name: string) => {
    const match = RegExp("[?&]" + name + "=([^&]*)").exec(url);
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};

export const getPathFromUrl = (url: string) => {
    return url && url.split("?")?.[0];
};

export const slugifyText = (input: string) => {
    if (!input) return "";

    // make lower case and trim
    let slug = input.toLowerCase().trim();

    // remove accents from charaters
    slug = slug.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    // replace invalid chars with spaces
    slug = slug.replace(/[^a-z0-9\s-]/g, " ").trim();

    // replace multiple spaces or hyphens with a single hyphen
    slug = slug.replace(/[\s-]+/g, "-");

    return slug;
};

export const getNoteUrl = ({
    noteId,
    title,
    queryStrings = {},
    extraPaths = [],
}: {
    noteId: string;
    title?: string | null;
    queryStrings?: Record<string, QueryString>;
    extraPaths?: string[];
}) => {
    const baseUrl = `/note/${noteId}`;

    const cleanedTitle = "/" + (title ? cleanTitle(title).substring(0, 40) : UNTITLED);

    return addExtraUrlData({
        url: baseUrl + (cleanedTitle === "/" ? `/${TITLE_FILLER}` : cleanedTitle),
        queryStrings,
        extraPaths,
    });
};

export const addExtraUrlData = ({ url, queryStrings = {}, extraPaths: _extraPaths = [] }) => {
    const params = createQueryStrings(queryStrings, encodeURIComponent);
    const extraPaths = _extraPaths.filter(Boolean);
    return `${url}${extraPaths.length ? `/${extraPaths.join("/")}` : ""}${params}`;
};
