"use client";

import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import noop from "@knowt/syncing/utils/noop";
import React from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import { AuthEvent } from "../utils/constants";

// following ../AuthSlideDialog/AuthSlideContext.tsx "Show Login" & "Show Signup" addditional props
type AdditionalProps = {
    prefilledEmail?: string;
    onClose?: (e?: React.MouseEvent) => void;
};

export type OpenAuthPopupProps = {
    event: string;
    extra?: AdditionalProps;
    title?: string;
    description?: string;
    closeable?: boolean;
};

type AuthPopupContextProps = {
    authPopupStates: {
        isOpen: boolean;
        event: AuthEvent;
        extra?: AdditionalProps;
        title?: string | null;
        description?: string | null;
        closeable?: boolean;
    };
    openAuthPopup: (props: OpenAuthPopupProps) => void;
    closeAuthPopup: () => void;
};

const AuthPopupContext = createContext<AuthPopupContextProps>({
    authPopupStates: { isOpen: false, event: AuthEvent.GENERAL_SIGNUP, title: null, description: null },
    openAuthPopup: noop,
    closeAuthPopup: noop,
});

export const AuthPopupContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [authPopupStates, updateAuthPopupStates] = useCombinedState<AuthPopupContextProps["authPopupStates"]>({
        isOpen: false,
        event: AuthEvent.GENERAL_SIGNUP,
        title: "Sign up for free in <10 seconds!",
        description: null,
        closeable: true,
        extra: {
            prefilledEmail: "",
            onClose: noop,
        },
    });

    const openAuthPopup = ({
        event,
        extra,
        title = "Sign up for free in <10 seconds!",
        description = "You'll need an account to do this, but signing up is free and easy.",
        closeable = true,
    }) => {
        updateAuthPopupStates({ event, isOpen: true, extra, title, description, closeable });
    };

    const closeAuthPopup = () => {
        updateAuthPopupStates({ isOpen: false });
        // delay resetting props so that the UI does not change on close animation fade away
        setTimeout(() => {
            updateAuthPopupStates({
                event: AuthEvent.GENERAL_SIGNUP,
                extra: { prefilledEmail: "", onClose: noop },
            });
        }, 300);
    };

    return (
        <AuthPopupContext.Provider value={{ authPopupStates, openAuthPopup, closeAuthPopup }}>
            {children}
        </AuthPopupContext.Provider>
    );
};

export const useAuthPopupContextSelector = <T,>(selector: (state: AuthPopupContextProps) => T) =>
    useContextSelector(AuthPopupContext, selector);
