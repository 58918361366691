import { PRICING_OPTIONS, PricingOption } from "@knowt/syncing/hooks/user/subscriptionConstants";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { ASSETS_URL, BASE_URL } from "@/config/deployConstants";
import { themeColors } from "@/utils/themeColors";
import LinkWrapper from "@/components/wrappers/Link/Link";
import { TIME_SECONDS } from "@knowt/syncing/utils/dateTimeUtils";
import { capitalize } from "@knowt/syncing/utils/stringUtils";
import { UserDetails } from "@knowt/syncing/graphql/schema";

const crownImg = "/images/crown.svg";
const supporterImg = `${ASSETS_URL}/images/supporter_badge.png`;
const basicImg = `${ASSETS_URL}/images/knowt-logo-minimal.svg`;
const diamondImg = `${ASSETS_URL}/images/diamond.png`;

export type RefundType = "FULL" | "PARTIAL" | "NONE" | "TRIAL" | "FAILED";

export enum OfferTypes {
    UNIDAYS = "UNiDAYS",
    STUDENT_BEANS = "StudentBeans",
}

export const REFUNDS: {
    [key in RefundType]: RefundType;
} = {
    FULL: "FULL",
    PARTIAL: "PARTIAL",
    NONE: "NONE",
    TRIAL: "TRIAL",
    FAILED: "FAILED",
};

export const REFUND_TIME_LIMITS = {
    FULL: 2 * TIME_SECONDS.DAY, // canceling before 48 hours after auto reniewal
    PARTIAL: 3 * TIME_SECONDS.DAY, // canceling between 48 and 72 hours after auto reniewal
};

export const REFUND_OPTIONS = {
    [REFUNDS.TRIAL]: {
        title: "You’re really upsetting Kai 😭",
        description: subscriptionType =>
            `Kai wants to give you 15% off of your ${capitalize(subscriptionType)} subscription to keep it active.`,
        primary: "keep my subscription with 15% off",
        secondary: "no thanks, I want to cancel my subscription.",
        offer: "COUPON",
    },
    [REFUNDS.FULL]: {
        title: "You’re really upsetting Kai 😭",
        description: () =>
            `Your plan already renewed automatically. We can give you a full refund (minus the ~3.5% transaction fees incurred by Knowt for processing the refund) but you will lose access to features immediately, or we can also refund you 15% and you can keep your plan active!`,
        primary: "I’ll take a 15% refund and keep my plan",
        secondary: "no thanks, I want a full refund.",
        offer: "REFUND",
    },
    [REFUNDS.PARTIAL]: {
        title: "You’re really upsetting Kai 😭",
        description: () =>
            `Your plan already renewed automatically and it’s been more than 48 hours. We can only give you a 50% refund (minus the ~3.5% transaction fees incurred by Knowt for processing the refund), but you will lose access to features immediately, or we can refund you 15% and you can keep your plan active!`,
        primary: "I’ll take a 15% refund and keep my plan",
        secondary: "no thanks, I want a partial refund.",
        offer: "REFUND",
    },
    [REFUNDS.NONE]: {
        title: "You’re really upsetting Kai 😭",
        description: () =>
            `Your plan already renewed automatically and it’s been more than 72 hours. We can’t give you any refund as per the policy on knowt.com/plans.`,
        primary: "that’s ok, I’ll keep auto-renewal on",
        secondary: "no thanks, I’d like to turn off auto-renewal.",
    },
    [REFUNDS.FAILED]: {
        title: "You’re really upsetting Kai 😭",
        description: () => `Your last payment failed. There's nothing to refund.`,
        primary: "that’s ok, I’ll keep auto-renewal on",
        secondary: "no thanks, I’d like to turn off auto-renewal.",
    },
};

export const CANCEL_SUCCESS_REFUND_OPTIONS = {
    [REFUNDS.FULL]: {
        title: "Your subscription has been cancelled, and your refund will be processed in 5-10 business days.",
        description: () => (
            <p>
                We’re sad to see you cancelled your plan but you’ll still be able to use the free features in Knowt!
                You’ll see the balance return to your bank account in 5-10 business days -- but if you don’t please fill
                out the form on knowt.com/contact.
            </p>
        ),
    },
    [REFUNDS.PARTIAL]: {
        title: "Your subscription has been cancelled, and your refund will be processed in 5-10 business days.",
        description: () => (
            <p>
                We’re sad to see you cancelled your plan but you’ll still be able to use the free features in Knowt!
                You’ll see the balance return to your bank account in 5-10 business days -- but if you don’t please fill
                out the form on knowt.com/contact.
            </p>
        ),
    },
    [REFUNDS.NONE]: {
        title: "We’ve turned off auto-renewal for your subscription.",
        description: (date?: string) => (
            <p>
                We’re sad to see you want to cancel your plan but you’ll still be able to access the features on the
                plan until the plan ends on <span className="bodyBold1">{date}</span>! You weren’t eligible for a
                refund, but we’ve turned off your auto-renewal so you won’t get charged when your subscription ends on
                <span className="bodyBold1">{date}</span>
            </p>
        ),
    },
    [REFUNDS.TRIAL]: {
        title: "We've cancelled your trial, and you won't be charged.",
        description: () => (
            <p>
                We’re sad to see you cancelled your trial but you’ll still be able to use the free features in Knowt!
                You won’t be charged for the trial, but if you have any questions please fill out the form on
                knowt.com/contact.
            </p>
        ),
    },
    [REFUNDS.FAILED]: {
        title: "Your last payment failed. There's nothing to refund. We've turned off auto-renewal for your subscription.",
        description: () => (
            <p>
                We’re sad to see you cancelled your plan. but you’ll still be able to use the free features in Knowt!
                You won’t be charged for any future payments, but if you have any questions please fill out the form on
                knowt.com/contact.
            </p>
        ),
    },
};

export type PricingPropsUI = PricingOption & {
    description?: string;
    subtitle: string;
    features: React.ReactElement[];
    onClick?: () => void;
    icon?: string;
    background?: string;
    buttonBackground?: string;
    promo?: number;
    mostPopular?: boolean;
    benefits?: React.ReactElement[];
};

// TODO: pricingProps to `pricingCard` as props instead
export type PricingCardProps = PricingPropsUI & {
    buttonText?: string;
    renderTopBadge?: React.ReactNode;
    isCurrentPlan?: boolean;
};

const MONTHLY_BASIC: PricingPropsUI = {
    ...PRICING_OPTIONS.MONTHLY_BASIC,
    subtitle: "Study for free on Knowt using our core features, with ads",
    description: "",
    features: [<>Unlimited Learn Mode</>, <>Unlimited Videos & PDFs</>],
    onClick: () => Mixpanel.track("Basic Plan - Clicked", { price: 0 }),
    icon: basicImg,
};

const MONTHLY_SUPPORTER: PricingPropsUI = {
    ...PRICING_OPTIONS.MONTHLY_ADFREE,
    description: 'Everything on "Free" and',
    subtitle: "No ads & the ability to personalize",
    features: [
        <>No Ads</>,
        <>Folder color, profile page customization & password protecting files</>,
        <>Unlimited explanations on incorrect answers</>,
    ],
    onClick: () => Mixpanel.track("Ad Free Plan Monthly - Clicked", { price: 1.99 }),
    icon: supporterImg,
    benefits: [
        <>No Ads</>,
        <>Folder color & profile page customization</>,
        <>Unlimited explanations on incorrect answers</>,
        <>Password protect files</>,
        <>Public profile badge</>,
        <>100 AI generated flashcard definitions per month</>,
        <>100 AI note prompts per month</>,
        <>30 AI generated flashcard sets from notes per month</>,
        <>30 AI generated tests from notes per month </>,
    ],
};

const ANNUALLY_SUPPORTER: PricingPropsUI = {
    ...PRICING_OPTIONS.ANNUALLY_ADFREE,
    description: 'Everything on "Free" and',
    subtitle: "No ads & the ability to personalize",
    features: [
        <>No Ads</>,
        <>Folder color, profile page customization & password protecting files</>,
        <>Unlimited explanations on incorrect answers</>,
    ],
    onClick: () => Mixpanel.track("Ad Free Plan Annually - Clicked", { price: 11.88 }),
    icon: supporterImg,
    benefits: [
        <>No Ads</>,
        <>Folder color & profile page customization</>,
        <>Unlimited explanations on incorrect answers</>,
        <>Password protect files</>,
        <>Public profile badge</>,
        <>100 AI generated flashcard definitions per month</>,
        <>100 AI note prompts per month</>,
        <>30 AI generated flashcard sets from notes per month</>,
        <>30 AI generated tests from notes per month </>,
    ],
};

const MONTHLY_PRO: PricingPropsUI = {
    ...PRICING_OPTIONS.MONTHLY_PRO,
    description: 'Everything on "Ad Free" and',
    subtitle: "For those who want to use AI a little bit",
    features: [
        <>Create Flashcards from your notes using AI</>,
        <>Have AI create 5 notes and flashcards from your videos & PDFs</>,
        <>Unlimited explanations on incorrect answers</>,
    ],
    onClick: () => Mixpanel.track("Pro Plan Monthly - Clicked", { price: 4.99 }),
    icon: crownImg,
    benefits: [
        <>No Ads</>,
        <>Folder color & profile page customization</>,
        <>Password protect files</>,
        <>Public profile badge</>,
        <>Unlimited explanations on incorrect answers</>,
        <>250 AI generated flashcard definitions per month</>,
        <>250 AI note prompts per month</>,
        <>50 AI generated flashcard sets from notes per month</>,
        <>50 AI generated tests from notes per month</>,
        <>5 AI generated notes from videos per month</>,
        <>5 AI generated flashcards from videos per month</>,
        <>100 chat messages with videos per month</>,
        <>5 AI generated notes from PDFs per month</>,
        <>5 AI generated flashcards from PDFs per month</>,
        <>100 chat messages with PDFs per month</>,
    ],
};

const ANNUALLY_PRO: PricingPropsUI = {
    ...PRICING_OPTIONS.ANNUALLY_PRO,
    description: 'Everything on "Ad Free" and',
    subtitle: "For those who want to use AI a little bit",
    features: [
        <>Create Flashcards from your notes using AI</>,
        <>Have AI create 5 notes and flashcards from your videos & PDFs</>,
        <>Unlimited explanations on incorrect answers</>,
    ],
    onClick: () => Mixpanel.track("Pro Plan Annually - Clicked", { price: 35.88 }),
    icon: crownImg,
    benefits: [
        <>No Ads</>,
        <>Folder color & profile page customization</>,
        <>Password protect files</>,
        <>Public profile badge</>,
        <>Unlimited explanations on incorrect answers</>,
        <>250 AI generated flashcard definitions per month</>,
        <>250 AI note prompts per month</>,
        <>50 AI generated flashcard sets from notes per month</>,
        <>50 AI generated tests from notes per month</>,
        <>5 AI generated notes from videos per month</>,
        <>5 AI generated flashcards from videos per month</>,
        <>100 chat messages with videos per month</>,
        <>5 AI generated notes from PDFs per month</>,
        <>5 AI generated flashcards from PDFs per month</>,
        <>100 chat messages with PDFs per month</>,
    ],
    promo: 40,
};

const MONTHLY_LIMITLESS: PricingPropsUI = {
    ...PRICING_OPTIONS.MONTHLY_LIMITLESS,
    description: 'Everything on "Pro" and',
    subtitle: "For the Knowt superstars! Unlimited use of all our AI features & more*",
    features: [
        <>Unlimited flashcards made from your notes using AI</>,
        <>Unlimited notes & flashcards made from your lecture videos with AI</>,
        <>Unlimited practice tests made from your lecture and PDFs with AI</>,
        <>Unlimited explanations on incorrect answers</>,
    ],
    onClick: () => Mixpanel.track("Limitless Plan Monthly - Clicked", { price: 12.99 }),
    icon: diamondImg,
    benefits: [
        <>No Ads</>,
        <>Folder color & profile page customization</>,
        <>Password protect files</>,
        <>Public profile badge</>,
        <>Unlimited explanations on incorrect answers</>,
        <>Unlimited AI generated flashcard definitions per month</>,
        <>Unlimited AI note prompts per month</>,
        <>Unlimited AI generated flashcard sets from notes per month</>,
        <>Unlimited AI generated tests from notes per month</>,
        <>Unlimited AI generated notes from videos per month</>,
        <>Unlimited AI generated flashcards from videos per month</>,
        <>Unlimited chat messages with videos per month</>,
        <>Unlimited AI generated notes from PDFs per month</>,
        <>Unlimited AI generated flashcards from PDFs per month</>,
        <>Unlimited chat messages with PDFs per month</>,
    ],
    mostPopular: true,
    buttonBackground: "#50D2C2",
    background: themeColors.neutralBlack,
};

const ANNUALLY_LIMITLESS: PricingPropsUI = {
    ...PRICING_OPTIONS.ANNUALLY_LIMITLESS,
    description: 'Everything on "Pro" and',
    subtitle: "Unlimited usage of all our features*",
    features: [
        <>Unlimited flashcards made from your notes using AI</>,
        <>Unlimited notes & flashcards made from your lecture videos with AI</>,
        <>Unlimited practice tests made from your lecture and PDFs with AI</>,
        <>Unlimited explanations on incorrect answers</>,
    ],
    onClick: () => Mixpanel.track("Limitless Plan Annually - Clicked", { price: 119.88 }),
    icon: diamondImg,
    benefits: [
        <>No Ads</>,
        <>Folder color & profile page customization</>,
        <>Password protect files</>,
        <>Public profile badge</>,
        <>Unlimited explanations on incorrect answers</>,
        <>Unlimited AI generated flashcard definitions per month</>,
        <>Unlimited AI note prompts per month</>,
        <>Unlimited AI generated flashcard sets from notes per month</>,
        <>Unlimited AI generated tests from notes per month</>,
        <>Unlimited AI generated notes from videos per month</>,
        <>Unlimited AI generated flashcards from videos per month</>,
        <>Unlimited chat messages with videos per month</>,
        <>Unlimited AI generated notes from PDFs per month</>,
        <>Unlimited AI generated flashcards from PDFs per month</>,
        <>Unlimited chat messages with PDFs per month</>,
    ],
    promo: 25,
    mostPopular: true,
    buttonBackground: "#50D2C2",
    background: themeColors.neutralBlack,
};

export const PRICING_OPTIONS_UI = [MONTHLY_SUPPORTER, ANNUALLY_SUPPORTER, MONTHLY_LIMITLESS, MONTHLY_PRO, ANNUALLY_PRO];

export const PRICING_COMPARISON_UI = [
    MONTHLY_BASIC,
    MONTHLY_SUPPORTER,
    ANNUALLY_SUPPORTER,
    MONTHLY_PRO,
    ANNUALLY_PRO,
    MONTHLY_LIMITLESS,
    ANNUALLY_LIMITLESS,
];

export const PRICING_OPTIONS_DETAILS = {
    MONTHLY_BASIC,
    MONTHLY_SUPPORTER,
    ANNUALLY_SUPPORTER,
    MONTHLY_PRO,
    ANNUALLY_PRO,
    MONTHLY_LIMITLESS,
};

// TODO (refactor): probably move to syncing?
export const getPricingOptionUI = (user?: UserDetails | null): PricingPropsUI => {
    if (!user || !user.subscriptions?.[0]) {
        return PRICING_OPTIONS_DETAILS.MONTHLY_BASIC;
    }

    return (
        PRICING_OPTIONS_UI.find(({ subscriptionType, interval }) => {
            return (
                subscriptionType === user.subscriptionType &&
                interval.toLowerCase() === user.subscriptions?.[0].interval?.toLowerCase()
            );
        }) ?? PRICING_OPTIONS_DETAILS.MONTHLY_BASIC
    );
};

export const faqs = [
    {
        question: "Is there a free trial for the Knowt plans?",
        answer: "Yes! We start you off with some complimentary uses of all our AI features so you can test it out before you choose a plan. Once you've used up your complimentary features or if you want to try one of our exclusive premium features, you can sign up for a 7 day free trial of our Ad Free, Pro & Limitless premium plans!",
    },
    {
        question: "What's included in the Free plan?",
        answer: [
            "You have our guarantee that the core features on Knowt will ALWAYS be free! You can create notes & flashcard sets, study flashcards with unlimited rounds of learn mode or any of our study modes and use our dual-screen video & PDF editors completely free.",
            "However, if you're looking to upgrade your learning with AI features we've uniquely built to help you save time and energy when studying, we highly recommend trying out a free trial for one of our premium plans.",
        ],
    },
    {
        question: "How can I get a discount on the Knowt premium plans? ",
        answer: "You get a 25% discount off our Limitless plan & 40% discount off our Pro plan when you choose the annual billing option!",
    },
    {
        question: "Can I purchase one of these plans for my organization at a cheaper price?",
        answer: (
            <span>
                If you like to purchase ad-free Knowt or one of our premium plans for your organization, we offer
                discounted pricing! Please fill out this{" "}
                <LinkWrapper openInNewTab href={"https://airtable.com/appM2tJayn1Sir7PD/shr2XOhT4qubcbKdr"}>
                    form
                </LinkWrapper>{" "}
                and our CEO will get in touch with you shortly.
            </span>
        ),
    },
    {
        question: "How do refunds work?",
        answer: [
            "Our refund policy is simple. If you believe you mistakenly signed up or renewed a paid Knowt subscription, contact us at knowt.com/contact within 48 hours of renewal or initial subscription and we’ll refund you the full amount (minus the ~3.5% transaction fees incurred by Knowt for processing the refund). You can reach out up to 72 hours after in order to receive a 50% refund as well (minus the ~3.5% transaction fees incurred by Knowt for processing the refund).",
            "If for some reason, you’re unhappy with your Knowt subscription, please email us at knowt.com/contact and let us know. We want to make your experience studying on Knowt the best it can be, so we are always open to hearing your feedback.",
        ],
    },
    {
        question: "How do I change my payment method?",
        answer: "You can change your payment method at any time by going to your Settings page, scrolling down to the Plans section and clicking Manage Billing.",
    },
    {
        question: "What happens when I change plans?",
        answer: [
            "When you upgrade your plan, your account will be upgraded and charge the amount due immediately. The amount of money you pay will be automatically reduced based on the percentage of the billing cycle left at the time the plan was changed.",
            "When you downgrade a plan, your account will be downgraded at the end of your billing cycle.",
        ],
    },
    {
        question: "How do I cancel my subscription?",
        answer: "Your Knowt subscription, annually and monthly, automatically renews until you cancel it. You can cancel your subscription at any time by going to your Settings page, scrolling down to the Plans section and clicking Manage Billing. Once you cancel your subscription, you'll have access to all the paid features until the end of your billing cycle.",
    },
];

const baseLink = "https://help.knowt.com/hc/en-us/articles/";
export const LearnHowLinks = {
    practiceTestLink: BASE_URL + "/ai-notes",
    flashcardFromNotesLink: BASE_URL + "/ai-notes",
    brainstormingLink: baseLink + "19743583074068-How-to-use-AI-for-your-notes",
    customizeProfileLink: baseLink + "19743195305108-How-to-update-your-profile-page",
    passwordProtectLink: baseLink + "19901968764692-Password-protect-your-files",
    notesFromVideoLink: BASE_URL + "/ai-pdf-summarizer",
};
