import Image from "next/image";
import CircularButton, { CircularButtonProps } from "@/components/CircularButton";
import { themeColors } from "@/utils/themeColors";

type NavbarCircularBtnProps = CircularButtonProps & { image?: string; children?: React.ReactNode };

const NavbarCircularBtn = ({ image, onClick, children, sx, ...btnProps }: NavbarCircularBtnProps) => {
    return (
        <CircularButton
            onClick={onClick}
            radius="4rem"
            sx={{
                backgroundColor: "transparent",
                border: `1px solid ${themeColors.neutralBlack}`,
                ...sx,
            }}
            {...btnProps}>
            {image && <Image alt="image" src={image} height="17" width={"17"} style={{ objectFit: "contain" }} />}
            {children}
        </CircularButton>
    );
};

export default NavbarCircularBtn;
