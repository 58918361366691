"use client";
import { Search, SearchIcon } from "lucide-react";
import { useState } from "react";
import NavbarCircularBtn from "./styled/NavbarCircularBtn";
import LazyLoaded from "@/components/LazyLoaded";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import dynamic from "next/dynamic";
import br from "@/styles/breakpoints.module.css";
// eslint-disable-next-line no-restricted-imports
import { SxProps } from "@mui/material";
import { borderRadius } from "@/utils/borderRadius";

const SearchPopup = dynamic(() => import("@/components/Popup/SearchPopup"));

type NavbarSearchbarProps = {
    placeholder?: string;
    id: string;
    alwaysFullWidth?: boolean;
    sx?: SxProps;
};

const NavbarSearchbar = ({ placeholder, id, alwaysFullWidth, sx }: NavbarSearchbarProps) => {
    const [isSearchPopupOpen, setIsSearchPopupOpen] = useState(false);

    return (
        <>
            {!alwaysFullWidth && (
                <div className={br.smUpDisplayNone} key={"mobile-search-bar"}>
                    <NavbarCircularBtn
                        id="main-search-bar"
                        key="mobile-search"
                        className={br.smUpDisplayNone}
                        onClick={() => setIsSearchPopupOpen(true)}>
                        <Search size={iconSizes.SM} strokeWidth={strokeWidth.normal} />
                    </NavbarCircularBtn>
                </div>
            )}
            <div className={!alwaysFullWidth ? br.smDownDisplayNone : ""} key={"desktop-search-bar"}>
                <CircularRectTextButton
                    id={id}
                    className="ellipsisText"
                    sx={{
                        width: "100%",
                        height: "4rem",
                        borderRadius: borderRadius.longInput,
                        color: themeColors.neutral2,
                        backgroundColor: themeColors.background,
                        "&:hover": {
                            boxShadow: "none",
                            color: themeColors.neutral3,
                        },
                        fontSize: "1.4rem",
                        gap: "1rem",
                        padding: "0rem 2rem",
                        justifyContent: "flex-start",
                        ...sx,
                    }}
                    onClick={() => setIsSearchPopupOpen(true)}>
                    <SearchIcon size={iconSizes.SM} />
                    <div className="ellipsisText">{placeholder || "Search for anything"}</div>
                </CircularRectTextButton>
            </div>

            <LazyLoaded load={isSearchPopupOpen}>
                <SearchPopup
                    folders={{}}
                    placeholder={"Search your notes, flashcards, subjects, topics, people..."}
                    isOpen={isSearchPopupOpen}
                    close={() => setIsSearchPopupOpen(false)}
                />
            </LazyLoaded>
        </>
    );
};

export default NavbarSearchbar;
