export const VARIANTS = {
    REFERRAL: {
        title: "How did you hear about Knowt?",
        subtitle: "We’ll only ask for this information once.",
        buttons: [
            {
                selected: false,
                label: "Tiktok",
                icon: "/images/tiktok-icon-black.svg",
                darkIcon: "/images/tiktok-icon-white.svg",
            },
            { selected: false, label: "Google", icon: "/images/google-icon.svg" },
            { selected: false, label: "Instagram", icon: "/images/instagram-icon.svg" },
            { selected: false, label: "Friends/Family", icon: "/images/family-icon.svg" },
            { selected: false, label: "Youtube", icon: "/images/yt-icon.svg" },
            { selected: false, label: "Blog/Article", icon: "/images/blog-icon.svg" },
            { selected: false, label: "Teacher", icon: "/images/teacher-icon.svg" },
            { selected: false, label: "UNiDAYS", icon: "/images/UNiDAYS.png" },
            { selected: false, label: "Library", icon: "/images/books.svg" },
            { selected: false, label: "Other", icon: "/images/other-icon.svg" },
        ],
    },
    GOAL: {
        title: "What’s your goal when using Knowt?",
        subtitle: "We’ll only ask for this information once.",
        buttons: [
            { selected: false, label: "Upload PDFs, PowerPoints, Docs, etc...", icon: "/images/drive.svg" },
            { selected: false, label: "Upload Lecture Videos", icon: "/images/video-icon.svg" },
            { selected: false, label: "Import Quizlets to study for free", icon: "/images/sord-icon.svg" },
            { selected: false, label: "Create & study flashcards", icon: "/images/flashcard-gray-icon.svg" },
            { selected: false, label: "Studying from other’s flashcards", icon: "/images/world-icon.svg" },
            { selected: false, label: "Study with the AP/IB/GCSE Study Guides", icon: "/images/crown.png" },
            { selected: false, label: "Create notes & study from them", icon: "/images/note-icon.png" },
            { selected: false, label: "Other", icon: "/images/other-icon.svg" },
        ],
    },
};
