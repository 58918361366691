"use client";

import clsx from "clsx";
import Link from "next/link";
import React from "react";
import linkStyles from "@/components/wrappers/Link/link.module.css";
import { usePathname } from "next/navigation";

export type LinkWrapperProps = React.ComponentProps<typeof Link> & {
    children: React.ReactNode;
    openInNewTab?: boolean;
};

const LinkWrapper = ({
    children,
    className,
    onClick: _onClick,
    href: _href,
    style: _style,
    openInNewTab = false,
    ...props
}: LinkWrapperProps) => {
    const pathname = usePathname();
    // split the href into link and anchor
    const [link, anchor] = _href?.toString()?.split("#") || [];
    const asDiv = pathname === link;

    const generateLinkProps = () => {
        let scrollFunction = () => window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        if (anchor) {
            const anchorElement = document.getElementById(anchor);
            if (anchorElement) {
                scrollFunction = () => anchorElement.scrollIntoView({ behavior: "smooth", block: "center" });
            }
        }

        return {
            href: (asDiv ? undefined : _href) as string,
            onClick: asDiv
                ? scrollFunction
                : e => {
                      e.stopPropagation();
                      _onClick?.(e);
                  },
            ...(!asDiv && { prefetch: false }),
        };
    };

    const Component = asDiv ? "span" : Link;

    return (
        <Component
            className={clsx(linkStyles.link, className)}
            target={openInNewTab ? "_blank" : undefined}
            style={{ cursor: asDiv ? "pointer" : undefined, ..._style }}
            {...generateLinkProps()}
            {...props}>
            {children}
        </Component>
    );
};

export default LinkWrapper;
