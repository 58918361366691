"use client";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import Portal from "@mui/base/Portal";
import { Plus } from "lucide-react";
import { useRouter } from "next13-progressbar";
import dynamic from "next/dynamic";
import Image from "next/image";
import React, { useState } from "react";
import ActionArrowRight from "../Circular/styled/ActionArrowRight";
import CircularOutlineIcon from "../CircularButton/styled/CircularOutlineIcon";
import FilledIconTextButton from "../CircularButton/styled/FilledIconTextButton";
import { themeColors } from "@/utils/themeColors";
import { iconSizes } from "@/utils/iconProps";
import WithOptionsMenu, { WithMenuOption } from "@/components/WithOptionsMenu";
import LazyLoaded from "@/components/LazyLoaded";
import { FlexRowAlignCenter } from "@/components/Flex";
import { useCheckAuth } from "@/hooks/useCheckAuth";
import { ASSETS_URL } from "@/config/deployConstants";
import {
    WALKTRHOUGH_BEGIN_CREATE_BTN,
    WALKTRHOUGH_CREATE_PDF_BTN,
    WALKTHROUGH_ADD_FILE_BTN,
} from "@/features/UserContentManagement";
import { AuthEvent } from "@/features/Auth";
import { useFolder } from "@knowt/syncing/hooks/folders/useFolders";
import { createQueryStrings, isDarkColor } from "@/utils/genericUtils";
import { ItemType } from "@knowt/syncing/graphql/schema";

const ImportFromQuizletPopup = dynamic(() => import("@/components/Popup/ImportFromQuizletPopup"));
const ImportPopup = dynamic(() => import("@/components/Popup/ImportPopup"));
const AVImportPopup = dynamic(() => import("@/features/MediaPage/components/popups/AVImportPopup"));

const AddClassFilePopup = dynamic(() =>
    import("@/features/UserContentManagement").then(({ AddClassFilePopup }) => AddClassFilePopup)
);

const MoveFileToClassPopup = dynamic(() =>
    import("@/features/UserContentManagement").then(({ MoveFileToClassPopup }) => MoveFileToClassPopup)
);

const noteIcon = `${ASSETS_URL}/images/note-icon.svg`;
const flashcardIcon = `${ASSETS_URL}/images/flashcard-icon.svg`;
const videoIcon = `${ASSETS_URL}/images/video-icon.svg`;
const quizletIcon = `${ASSETS_URL}/images/quizlet-icon.svg`;
const pdfIcon = `${ASSETS_URL}/images/pdf-icon.svg`;
const blackBoardIcon = `/images/blackboard.svg`;
const moveFileIcon = `${ASSETS_URL}/images/move-file-icon.svg`;

const CreateOption = ({ label, image }: { label: string; image: string }) => {
    return (
        <FlexRowAlignCenter style={{ justifyContent: "space-between" }}>
            <FlexRowAlignCenter style={{ gap: "1rem" }}>
                <Image src={image} alt={label} width={"20"} height={"20"} style={{ objectFit: "contain" }} />
                <span style={{ fontSize: "1.5rem", fontStyle: "normal", fontWeight: 600, lineHeight: "normal" }}>
                    {label}
                </span>
            </FlexRowAlignCenter>
            <ActionArrowRight style={{ backgroundColor: undefined }} />
        </FlexRowAlignCenter>
    );
};

type CreateNewContentButtonWithHandlerProps = {
    onlyShowIcon?: boolean;
    /** either to add create/join class option or not */
    classMode?: boolean;
    /** override the button color */
    color?: string;
    /** if present then the created note/flashcards will have `classId` in the obj */
    classId?: string;
    /** if present then the created note/flashcards will have `folderId` in the obj */
    folderId?: string;
};

export const CreateNewContentButtonWithHandler = ({
    onlyShowIcon = false,
    classMode = false,
    color,
    classId,
    folderId,
}: CreateNewContentButtonWithHandlerProps) => {
    const { user, userId, isTeacher } = useCurrentUser();

    const router = useRouter();
    const { checkAuth } = useCheckAuth();
    const { folder } = useFolder({ folderId });

    const [isImportFlashcardsPopupOpen, setIsImportFlashcardsPopupOpen] = useState(false);
    const [isImportNotesPopupOpen, setIsImportNotesPopupOpen] = useState(false);
    const [isImportVideosPopupOpen, setIsImportVideosPopupOpen] = useState(false);

    //in Class Mode
    const [isAddClassFilePopupOpen, setIsAddClassFilePopupOpen] = useState(false);
    const [isMoveFileToClassPopupOpen, setIsMoveFileToClassPopupOpen] = useState(false);
    const [desiredFileType, setDesiredFileType] = useState<ItemType | null>(null);

    const createOptions: WithMenuOption[] = [
        ...(!user || user?.accountType === "Student"
            ? [
                  {
                      label: "Join class",
                      node: <CreateOption label={"Join class"} image={blackBoardIcon} />,
                      onClick: () => {
                          checkAuth(() => router.push("/class/join"), { event: AuthEvent.JOIN_CLASS });
                      },
                      menuItemProps: {
                          sx: { borderTop: `1px solid ${themeColors.neutral1}`, paddingBlock: "1.5rem" },
                      },
                  },
              ]
            : []),
        ...(user?.accountType === "Teacher"
            ? [
                  {
                      label: "Create class",
                      node: <CreateOption label={"Create class"} image={blackBoardIcon} />,
                      onClick: () => {
                          checkAuth(() => router.push(`/class/create`), { event: AuthEvent.CREATE_CLASS });
                      },
                      menuItemProps: {
                          sx: { borderBottom: `1px solid ${themeColors.neutral1}`, paddingBlock: "1.5rem" },
                      },
                  },
              ]
            : []),
        {
            label: "Notes",
            node: <CreateOption label={"Notes"} image={noteIcon} />,
            onClick: async () => {
                if (!user) {
                    router.push("/ai-notes");
                    return;
                }

                const { createAndOpenNewNote } = await import("@/utils/navigation/notes");

                const props = {};
                if (folderId) props["folderId"] = folderId;
                if (classId) props["classId"] = classId;

                await createAndOpenNewNote(props, user, router);
            },
            menuItemProps: {
                sx: {
                    paddingTop: "1.5rem",
                },
            },
        },
        {
            label: "Flashcards",
            node: <CreateOption label={"Flashcards"} image={flashcardIcon} />,
            onClick: () =>
                checkAuth(
                    async () => {
                        const { makeStandaloneFlashcardSet } = await import("@/hooks/flashcards/utils");

                        const { flashcardSetId } = await makeStandaloneFlashcardSet({
                            userId,
                            ...(folderId ? { folderId, public: folder?.public, password: folder?.password } : {}),
                            ...(classId ? { classId } : {}),
                        });

                        router.push(`/flashcards/${flashcardSetId}/edit`);
                    },
                    {
                        event: AuthEvent.CREATE_FLASHCARDS,
                    }
                ),
        },
        {
            label: "AI Video Summarizer",
            node: <CreateOption label={"AI Video Summarizer"} image={videoIcon} />,
            onClick: () => {
                setIsImportVideosPopupOpen(true);
            },
        },
        {
            label: "AI PDF Summarizer",
            node: <CreateOption label={"AI PDF Summarizer"} image={pdfIcon} />,
            menuItemProps: { className: WALKTRHOUGH_CREATE_PDF_BTN },
            onClick: () => router.push(`/ai-pdf-summarizer${createQueryStrings({ folderId, classId })}`),
        },
        {
            label: "AI PPT Summarizer",
            node: <CreateOption label={"AI PPT Summarizer"} image={`/images/ppt-icon.svg`} />,
            onClick: () => router.push(`ai-powerpoint-summarizer${createQueryStrings({ folderId, classId })}`),
        },
        {
            label: "AI Excel Summarizer",
            node: <CreateOption label={"AI Excel Summarizer"} image={`/images/excel-icon.svg`} />,
            onClick: () => router.push(`ai-spreadsheet-summarizer${createQueryStrings({ folderId, classId })}`),
        },
        {
            label: "Import from Quizlet",
            node: <CreateOption label={"Import from Quizlet"} image={quizletIcon} />,
            onClick: () => {
                checkAuth(() => setIsImportFlashcardsPopupOpen(true), { event: AuthEvent.IMPORT_FLASHCARDS });
            },
            menuItemProps: {
                sx: {
                    paddingBottom: "1.5rem",
                },
            },
        },
    ];

    const addClassFileOptions: WithMenuOption[] = [
        {
            label: "Move existing file",
            node: <CreateOption label={"Move existing file"} image={moveFileIcon} />,
            onClick: () => {
                checkAuth(
                    () => {
                        setIsMoveFileToClassPopupOpen(true);
                    },
                    { event: AuthEvent.ADD_CLASS_FILE }
                );
            },
        },
        {
            label: "Create Flashcards",
            node: <CreateOption label={"Create Flashcards"} image={flashcardIcon} />,
            onClick: () => {
                checkAuth(
                    () => {
                        setDesiredFileType(ItemType.FLASHCARDSET);
                        setIsAddClassFilePopupOpen(true);
                    },
                    { event: AuthEvent.ADD_CLASS_FILE }
                );
            },
        },
        {
            label: "Import from Quizlet",
            node: <CreateOption label={"Import from Quizlet"} image={quizletIcon} />,
            onClick: () => {
                checkAuth(() => setIsImportFlashcardsPopupOpen(true), { event: AuthEvent.ADD_CLASS_FILE });
            },
        },
        {
            label: "Create Notes",
            node: <CreateOption label={"Create Notes"} image={noteIcon} />,
            onClick: () => {
                checkAuth(
                    () => {
                        setDesiredFileType(ItemType.NOTE);
                        setIsAddClassFilePopupOpen(true);
                    },
                    { event: AuthEvent.ADD_CLASS_FILE }
                );
            },
        },
    ];

    const handleCreateClass = () => {
        if (!isTeacher) {
            return checkAuth(() => router.push("/class/join"), { event: AuthEvent.JOIN_CLASS });
        }
        router.push(`/class/create`);
    };

    const buttonText = classId ? `add files` : !isTeacher && classMode ? `join a class` : "create new";

    const renderButton = (openMenu: (e: React.MouseEvent) => void) => {
        const iconColor = color
            ? isDarkColor(color)
                ? themeColors.pureWhite
                : themeColors.pureBlack
            : themeColors.primaryDark;

        if (onlyShowIcon) {
            return (
                <CircularOutlineIcon
                    id="create-new-content-button"
                    size={iconSizes.MD_S}
                    Icon={Plus}
                    onClick={e => {
                        if (classMode) {
                            e.stopPropagation();
                            handleCreateClass();
                        } else {
                            openMenu(e);
                        }
                    }}
                    buttonColor={color ?? themeColors.primary}
                    borderColor={iconColor}
                    color={iconColor}
                    hoverColor={themeColors.neutralWhite}
                    tooltip={classMode ? (isTeacher ? `create a new class` : `join a class`) : `create new content`}
                />
            );
        }

        return (
            <div
                className={classId ? WALKTHROUGH_ADD_FILE_BTN : WALKTRHOUGH_BEGIN_CREATE_BTN}
                style={{ position: "relative" }}>
                <FilledIconTextButton
                    Icon={Plus}
                    text={buttonText}
                    buttonColor={color}
                    color={iconColor}
                    onClick={e => {
                        if (classMode) {
                            e.stopPropagation();
                            handleCreateClass();
                        } else {
                            openMenu(e);
                        }
                    }}
                />
            </div>
        );
    };

    return (
        <React.Fragment>
            <WithOptionsMenu
                options={classId ? addClassFileOptions : createOptions}
                menuProps={{
                    PaperProps: {
                        sx: {
                            marginTop: "1rem",
                            borderRadius: "2rem",
                            "& ul": {
                                padding: "0",
                            },
                        },
                    },
                    anchorOrigin: { vertical: "bottom", horizontal: onlyShowIcon ? "right" : "left" },
                    transformOrigin: { vertical: "top", horizontal: onlyShowIcon ? "right" : "left" },
                }}
                menuItemProps={{ sx: { padding: "1rem 2rem", width: "35rem", margin: "0" } }}>
                {({ openMenu }) => renderButton(openMenu)}
            </WithOptionsMenu>
            <LazyLoaded load={isImportFlashcardsPopupOpen}>
                <Portal>
                    <ImportFromQuizletPopup
                        isOpen={isImportFlashcardsPopupOpen}
                        onClose={() => setIsImportFlashcardsPopupOpen(false)}
                        onImportClick={async ({ title, flashcards }) => {
                            const { makeStandaloneFlashcardSet } = await import("@/hooks/flashcards/utils");

                            const { flashcardSetId } = await makeStandaloneFlashcardSet({
                                userId,
                                title,
                                flashcards,
                                ...(folderId ? { folderId, public: folder?.public, password: folder?.password } : {}),
                                ...(classId ? { classId } : {}),
                            });

                            router.push(`/flashcards/${flashcardSetId}/edit`);
                        }}
                    />
                </Portal>
            </LazyLoaded>
            <LazyLoaded load={isImportNotesPopupOpen}>
                <Portal>
                    <ImportPopup
                        isOpen={isImportNotesPopupOpen}
                        close={() => setIsImportNotesPopupOpen(false)}
                        importNewNote={async ({ content, title }) => {
                            const { createAndOpenNewNote } = await import("@/utils/navigation/notes");
                            await createAndOpenNewNote(
                                {
                                    content,
                                    title,
                                    ...(folderId ? { folderId } : {}),
                                },
                                user,
                                router
                            );
                        }}
                    />
                </Portal>
            </LazyLoaded>
            <LazyLoaded load={isImportVideosPopupOpen}>
                <Portal>
                    <AVImportPopup
                        isOpen={isImportVideosPopupOpen}
                        onClose={() => setIsImportVideosPopupOpen(false)}
                        classId={classId}
                    />
                </Portal>
            </LazyLoaded>
            <LazyLoaded load={isAddClassFilePopupOpen}>
                <Portal>
                    <AddClassFilePopup
                        isOpen={isAddClassFilePopupOpen}
                        onClose={() => setIsAddClassFilePopupOpen(false)}
                        classId={classId}
                        folderId={folderId}
                        openMoveFileToClassPopup={() => {
                            setIsAddClassFilePopupOpen(false);
                            setIsMoveFileToClassPopupOpen(true);
                        }}
                        itemType={desiredFileType}
                    />
                </Portal>
            </LazyLoaded>
            <LazyLoaded load={isMoveFileToClassPopupOpen}>
                <Portal>
                    <MoveFileToClassPopup
                        isOpen={isMoveFileToClassPopupOpen}
                        onClose={() => setIsMoveFileToClassPopupOpen(false)}
                        classId={classId}
                        folderId={folderId}
                    />
                </Portal>
            </LazyLoaded>
        </React.Fragment>
    );
};
