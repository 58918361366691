"use client";

import { createNewNote } from "@knowt/syncing/hooks/notes/utils";
import { Note, UserDetails } from "@knowt/syncing/graphql/schema";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { getNoteUrl } from "../url";
import { AppRouterInstance } from "next/dist/shared/lib/app-router-context.shared-runtime";

export const createAndOpenNewNote = async (
    initialFields: Partial<Note>,
    user: UserDetails | undefined,
    router: AppRouterInstance,
    params?: Record<string, unknown> | null,
    extraAction?: (noteId: string) => void
) => {
    if (!user) throw new Error("User must be logged in to create a note");

    const note = await createNewNote(initialFields, user);
    if (!note) throw new Error("Note creation failed");

    if (extraAction) extraAction(note.noteId);
    Mixpanel.track("Note - Created", { noteId: note.noteId, importType: note.importType });

    router.push(getNoteUrl({ noteId: note.noteId, title: note.title, queryStrings: { isNew: "true", ...params } }));
};
