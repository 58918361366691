export enum ClassDashboardTab {
    DASHBOARD = "dashboard",
    PEOPLE = "people",
    PROGRESS = "progress",
}

export enum ClassFilesTab {
    HOME = "dashboard",
    SAVED = "people",
}
