import { Rem } from "@/types/common";
import { LucideIcon } from "lucide-react";
import { forwardRef, useState } from "react";
import LoadingAnimation from "../../LoadingAnimation";
import CircularButton from "../CircularButton";
import { themeColors } from "@/utils/themeColors";
import { strokeWidth as strokeWidthEnum } from "@/utils/iconProps";
import ButtonBase from "@mui/material/ButtonBase";

export type CircularHoverIconProps = {
    id?: string;
    radius?: Rem;
    tabIndex?: number;
    buttonColor?: string;
    borderColor?: string;
    size: number;
    fill?: string;
    color?: string;
    hoverColor?: string;
    fillWhenActive?: boolean;
    isLoading?: boolean;
    style?: React.CSSProperties;
    isActive?: boolean;
    isDisabled?: boolean;
    activeColor?: string;
    onClick?: (e) => void;
    Icon: LucideIcon;
    strokeWidth?: number;
    className?: string;
    tooltip?: string;
    overrideHover?: boolean;
};

const CircularOutlineIcon = forwardRef(
    (
        {
            radius = "3.8rem",
            tabIndex,
            isDisabled,
            buttonColor = isDisabled ? "rgb(146, 146, 146, 0.3)" : "transparent",
            borderColor,
            color = themeColors.neutralBlack,
            hoverColor = themeColors.neutralWhite,
            isLoading = false,
            fill,
            fillWhenActive,
            isActive,
            style,
            activeColor = themeColors.primary,
            size,
            onClick,
            Icon,
            strokeWidth = strokeWidthEnum.normal,
            className,
            tooltip,
            overrideHover = false,
            id,
        }: CircularHoverIconProps,
        ref
    ) => {
        const [_isHover, setIsHover] = useState(false);
        const isHover = overrideHover || _isHover;

        const isButton = !!onClick;
        const isHoverable = isButton && !isDisabled;

        return (
            <CircularButton
                id={id}
                ref={ref}
                tabIndex={tabIndex}
                component={isButton ? ButtonBase : "div"}
                className={className}
                radius={radius}
                onMouseOver={() => setIsHover(isHoverable)}
                onMouseOut={() => setIsHover(false)}
                onClick={e => {
                    if (isDisabled || !isButton) return;
                    e.stopPropagation();
                    e.preventDefault();
                    onClick?.(e);
                    setIsHover(false);
                }}
                sx={{
                    "&:hover": {
                        backgroundColor: isHover ? borderColor ?? themeColors.neutralBlack : buttonColor,
                    },
                    "&:active": {
                        transform: "scale(0.95)",
                    },
                    border: "solid",
                    borderRadius: 999,
                    borderWidth: buttonColor === "transparent" || borderColor ? "1px" : 0,
                    borderColor: borderColor ?? themeColors.neutralBlack,
                    backgroundColor: buttonColor,
                    transition: "all ease 0.2s",
                    ...style,
                }}
                tooltip={tooltip}
                title={undefined}
                aria-label={undefined}>
                {isLoading ? (
                    <LoadingAnimation size={24} />
                ) : (
                    <Icon
                        size={size}
                        strokeWidth={strokeWidth ?? strokeWidthEnum.normal}
                        fill={isActive && fillWhenActive ? fill : "transparent"}
                        color={isActive ? fill ?? activeColor : isHover ? hoverColor : color}
                    />
                )}
            </CircularButton>
        );
    }
);

CircularOutlineIcon.displayName = "CircularHoverIcon";
export default CircularOutlineIcon;
