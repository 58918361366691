"use client";

import Image from "next/image";
import { VARIANTS } from "../constants";
import { spacing } from "@/utils/spacing";
import { useEffect, useState } from "react";
import { themeColors } from "@/utils/themeColors";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { Flex, FlexColumn } from "@/components/Flex";
import CircularButton from "@/components/CircularButton";
import { updateUserInfo } from "@knowt/syncing/hooks/user/utils";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { useExitFunction } from "@/hooks/useExitFunction";
import styles from "../InitialSetupPage.module.css";

const OptionButton = ({
    className,
    label,
    icon,
    selected,
    onClick,
    style,
}: {
    className?: string;
    label: string;
    icon: string;
    selected: boolean;
    onClick: () => void;
    style?: React.CSSProperties;
}) => {
    return (
        <CircularButton
            className={className}
            onClick={onClick}
            sx={{ "&:hover": { backgroundColor: themeColors.neutral1 } }}
            style={{
                height: "9rem",
                borderRadius: "1.5rem",
                border: `2px solid ${selected ? themeColors.neutral3 : themeColors.neutral1}`,
                justifyContent: "flex-start",
                padding: "3rem",
                backgroundColor: selected ? themeColors.neutral1 : "",
                ...style,
            }}>
            <Image src={icon} alt={label} width={37} height={37} />
            <div
                style={{
                    marginLeft: "2rem",
                    fontSize: "1.8rem",
                    fontWeight: 600,
                    fontFamily: "var(--knowt-font-name)",
                    textAlign: "left",
                }}>
                {label}
            </div>
        </CircularButton>
    );
};

const UserFeedbackPrompt = ({ variant = "REFERRAL", onNext }) => {
    const { title, subtitle, buttons } = VARIANTS[variant];
    const [optionsButtons, setOptionsButtons] = useState(buttons);

    useExitFunction(async () => {
        if (variant === "REFERRAL") {
            const userReferral = optionsButtons
                .filter(({ selected }) => selected)
                .map(({ label }) => label)
                .join(", ");

            await updateUserInfo({ referral: userReferral });
        }
    });

    useEffect(() => {
        setOptionsButtons(buttons);
    }, [buttons]);

    const selectOption = (idx: number) => {
        const newButtons = [...optionsButtons];
        newButtons[idx].selected = !newButtons[idx].selected;
        setOptionsButtons(newButtons);
    };

    const nextClick = async () => {
        if (variant === "REFERRAL") {
            const userReferral = optionsButtons
                .filter(({ selected }) => selected)
                .map(({ label }) => label)
                .join(", ");

            Mixpanel.track("User Feedback - Referral", { referral: userReferral.split(",") });
            await updateUserInfo({ referral: userReferral });
        } else {
            Mixpanel.track("User Feedback - Goal", {
                userGoal: optionsButtons.filter(({ selected }) => selected).map(({ label }) => label),
            });
        }
        onNext();
    };

    const renderHeader = () => {
        return (
            <FlexColumn style={{ gap: spacing.XXS }}>
                <h1 className="heading4">{title}</h1>
                <span className="body2">{subtitle}</span>
            </FlexColumn>
        );
    };

    const renderContent = () => {
        return (
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: "1.5rem",
                }}>
                {optionsButtons.map(({ label, icon, selected }, idx) => (
                    <OptionButton
                        className={styles.optionButton}
                        key={label}
                        label={label}
                        icon={icon}
                        selected={selected}
                        onClick={() => selectOption(idx)}
                    />
                ))}
            </div>
        );
    };

    const renderNextBtn = () => {
        return (
            <Flex style={{ justifyContent: "flex-end" }}>
                <CircularRectTextButton
                    onClick={nextClick}
                    sx={{
                        padding: `${spacing.XS_2} ${spacing.MD}`,
                        backgroundColor: themeColors.primary,
                    }}>
                    <span className="bodyBold2">next</span>
                </CircularRectTextButton>
            </Flex>
        );
    };

    return (
        <FlexColumn style={{ gap: spacing.LG_2 }}>
            {renderHeader()}
            {renderContent()}
            {renderNextBtn()}
        </FlexColumn>
    );
};

export default UserFeedbackPrompt;
