import { useSWRImmutable } from "@knowt/syncing/hooks/swr";
import { useCallback, useMemo } from "react";
import { useCurrentUser } from "../user/useCurrentUser";
import {
    callGetFolder,
    callListFoldersByClass,
    callListFoldersByParent,
    callListFoldersByUser,
    callUpdateFolder,
} from "@/hooks/folders/graphqlUtils";
import { resolveFoldersSWRKey, resolveFolderSWRKey, resolveNestedFoldersSWRKey } from "@/hooks/folders/utils";
import { platform } from "@/platform";
import { Folder, ItemType } from "@knowt/syncing/graphql/schema";
import { fromEntries } from "@/utils/genericUtils";
import { filterClassItems } from "@/utils/course";
import { populateCacheWithFallbackData } from "@/hooks/swr";

export const useAllFolders = ({
    otherUserId = null,
    isEnabled = true,
    fallbackData = undefined,
}: {
    otherUserId?: string | null;
    isEnabled?: boolean;
    fallbackData?: Record<string, Folder>;
} = {}) => {
    const { userId: currentUserId } = useCurrentUser();
    const userId = otherUserId || currentUserId;

    const {
        data: allFolders,
        error,
        mutate,
    } = useSWRImmutable(
        resolveFoldersSWRKey({ userId, isEnabled }),
        ([_, userId]) => callListFoldersByUser({ userId }),
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    return { allFolders, isLoading: !allFolders && !error, mutate };
};

export const useFolders = ({
    isEnabled = true,
    parentId = null,
    inTrash,
    fallbackData,
}: {
    isEnabled?: boolean;
    parentId?: string | null;
    inTrash?: boolean;
    fallbackData?: Record<string, Folder>;
}) => {
    const { allFolders, isLoading, mutate } = useAllFolders({ isEnabled, fallbackData });

    const filteredFolders: Record<string, Folder> = useMemo(() => {
        if (!allFolders) return null;

        return fromEntries(
            Object.entries(allFolders)
                .filter(([, folder]) => folder.trash === inTrash)
                .filter(([, folder]) => folder.classId === null)
                .filter(([, folder]) => folder.parentId === parentId)
        );
    }, [allFolders, parentId, inTrash]);

    return { folders: filteredFolders, isLoading, mutate };
};

export const useFolder = ({
    folderId,
    isEnabled = true,
    fallbackData = undefined,
}: {
    folderId: string | null | undefined;
    isEnabled?: boolean;
    fallbackData?: Folder | undefined;
}) => {
    const { userId } = useCurrentUser();

    const { data: folder, mutate } = useSWRImmutable(
        resolveFolderSWRKey({ folderId, isEnabled }),
        async ([_, folderId]) => {
            const storage = await platform.storage();
            const password = (await storage.getWithExpiry(`${ItemType.FOLDER}-${folderId}-pwd`)) as string;

            return await callGetFolder({ folderId, password });
        },
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    const update = useCallback(
        async (newFolderData: Partial<Folder>) => {
            if (!userId || !folder || !folderId) {
                return;
            }

            const updatedFields = { ...newFolderData, userId, folderId };

            //update cache
            await mutate(
                oldFolder => {
                    return { ...oldFolder, ...updatedFields };
                },
                { revalidate: false }
            );

            //update backend
            await callUpdateFolder(folderId, updatedFields).catch(async () => {
                await mutate(
                    oldFolder => {
                        return { ...oldFolder, ...folder };
                    },
                    { revalidate: true }
                );
            });
        },
        [userId, folder, folderId, mutate]
    );

    return { folder, update, mutate, readOnly: folder?.userId !== userId };
};

export const useFolderAncestors = ({
    folderId,
    otherUserId = null,
}: {
    folderId: string | null | undefined;
    otherUserId?: string;
}) => {
    const { allFolders, mutate } = useAllFolders({ otherUserId });

    const ancestors = useMemo(() => {
        if (!allFolders) return null;

        const ancestors: Folder[] = [];
        let currentFolder = allFolders[folderId];

        while (currentFolder) {
            ancestors.push(currentFolder);
            currentFolder = allFolders[currentFolder.parentId];
        }

        return ancestors.reverse();
    }, [allFolders, folderId]);

    return { ancestors, mutate };
};

export const useNestedFolders = ({
    parentId,
    inTrash = false,
    isEnabled = true,
    fallbackData,
}: {
    parentId: string;
    inTrash?: boolean;
    isEnabled?: boolean;
    fallbackData?: Record<string, Folder>;
}) => {
    const { userId } = useCurrentUser();

    const { data, error, mutate } = useSWRImmutable(
        resolveNestedFoldersSWRKey({ userId, parentId }),
        ([_, _userId, parentId]) => callListFoldersByParent({ parentId }),
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    const folders = useMemo(() => {
        if (!data) return undefined;
        return fromEntries(
            Object.entries(data).filter(([, folder]) => !!folder.trash === inTrash && folder.parentId === parentId)
        );
    }, [data, parentId, inTrash]);

    return { folders, isLoading: isEnabled && !folders && !error, mutate } as {
        folders: Record<string, Folder> | null;
        isLoading: boolean;
    };
};

export const useClassFolders = ({
    classId,
    sectionId,
    inTrash = false,
    fallbackData,
}: {
    classId: string;
    sectionId?: string | null | undefined;
    inTrash?: boolean;
    fallbackData?: Record<string, Folder>;
}) => {
    const { userId, user } = useCurrentUser();

    const { data, error, mutate } = useSWRImmutable(
        resolveFoldersSWRKey({ userId, classId }),
        () => callListFoldersByClass({ classId }),
        {
            fallbackData,
            use: [populateCacheWithFallbackData],
        }
    );

    const classFolders = useMemo(() => {
        if (!classId || !data) return {};
        return fromEntries(filterClassItems({ user, classId, items: data, inTrash, sectionId }));
    }, [data, inTrash, classId, sectionId, user]);

    return { classFolders, isLoading: !classFolders && !error, mutate };
};
