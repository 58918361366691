import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import { useRouter } from "next13-progressbar";
import React from "react";
// eslint-disable-next-line no-restricted-imports
import type { SxProps } from "@mui/material";
import { preventStopPropogation } from "@/utils/domUtils";
import { themeColors } from "@/utils/themeColors";
import Link from "@/components/wrappers/Link";

type withHref = { href: string; onClick?: never };
type withOnClick = { onClick: (e: React.MouseEvent) => void; href?: never };
export type WithMenuOption =
    | null
    | ({
          label: string;
          node?: React.ReactNode;
          hide?: boolean;
      } & (withHref | withOnClick) & { menuItemProps?: MenuItemProps });

export type WithOptionsMenuProps = {
    children: ({
        openMenu,
        closeMenu,
    }: {
        openMenu: (e: React.MouseEvent) => void;
        closeMenu: () => void;
    }) => React.ReactNode;
    header?: React.ReactNode;
    options: WithMenuOption[];
    menuItemProps?: MenuItemProps;
    menuProps?: Omit<MenuProps, "open">;
    preventClosingMenuOnOptionClick?: boolean;
};

/**
 * MUI `Menu` wrapper so that parent don't have to control the `ref`
 *
 * Customize the menu through `menuProps`. It supports all menu props listed here https://mui.com/material-ui/react-menu/
 * - The body of the menu is MUI Paper, so if you want to customize the body, customize it through `menuProps.PaperProps`
 * - Create gap between menu and button by passing margin to Paper's sx
 *
 * Customize the menu's item globally through `menuItemProps`.
 *
 * To customize custom style per-item, you can pass `menuItemProps` through option (`options.menuItemProps`)
 */
const WithOptionsMenu = ({
    children,
    header,
    options,
    menuItemProps,
    menuProps,
    preventClosingMenuOnOptionClick,
}: WithOptionsMenuProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const router = useRouter();

    const createHandleMenuClick = async (e: React.MouseEvent, menuItem: WithMenuOption) => {
        if (menuItem?.onClick) {
            menuItem.onClick(e);
        } else if (menuItem?.href) {
            router.push(menuItem?.href);
        }

        if (!preventClosingMenuOnOptionClick) {
            handleClose();
        }
    };

    const openMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(prev => (prev ? null : event.currentTarget));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {children({ openMenu, closeMenu: handleClose })}
            <Menu
                disableAutoFocusItem
                disableScrollLock
                open={!!anchorEl}
                onClose={(e: React.MouseEvent) => {
                    preventStopPropogation(e);
                    handleClose();
                }}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                {...menuProps}
                sx={{
                    // Opinionated styles since we don't want shadow by default
                    "& .MuiPaper-root": { boxShadow: "none" },
                    "& .MuiMenu-paper": {
                        backgroundColor: "var(--color-neutral-white)",
                    },
                    ...menuProps?.sx,
                }}
                BackdropProps={{
                    ...menuProps?.BackdropProps,
                    style: {
                        backdropFilter: "brightness(0.4)",
                        WebkitBackdropFilter: "brightness(0.4)",
                        ...menuProps?.BackdropProps?.style,
                    },
                }}
                PaperProps={{
                    sx: { borderRadius: "0", overflow: "hidden", padding: 0, ...menuProps?.sx },
                    ...menuProps?.PaperProps,
                }}>
                {header}
                {options.map((option, i) => {
                    if (!option || option.hide) return null;

                    const optionStyle: SxProps = {
                        ...menuItemProps?.sx,
                        ...option.menuItemProps?.sx,
                    };

                    return (
                        <MenuItem
                            {...menuItemProps}
                            {...option?.menuItemProps}
                            sx={{
                                "&:hover": {
                                    boxShadow: "inset 0 0 0 10em rgba(200, 200, 200, 0.15)",
                                },
                                position: "relative",
                                textOverflow: "ellipsis",
                                cursor: "pointer",
                                backgroundColor: themeColors.neutralWhite,
                                fontSize: "inherit",
                                margin: 0,
                                color: themeColors.neutralBlack,
                                fontFamily: "var(--knowt-font-name)",
                                zIndex: 9999,
                                ...optionStyle,
                            }}
                            key={String(option.label) + i}
                            onClick={e => {
                                preventStopPropogation(e);
                                createHandleMenuClick(e, option);
                            }}>
                            {option?.href ? (
                                <Link
                                    href={option?.href}
                                    style={{ width: "100%", textDecoration: "none", color: "inherit" }}>
                                    {option?.node || option?.label}
                                </Link>
                            ) : (
                                <span style={{ width: "100%" }}>{option?.node || option?.label}</span>
                            )}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};

export default WithOptionsMenu;
