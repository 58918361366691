import { ArrowRight } from "lucide-react";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import Circular, { CircularProps } from "../Circular";

type ActionArrowRightProps = Omit<CircularProps, "radius"> & {
    size?: number;
    radius?: string;
};

export default function ActionArrowRight({
    radius = "2.8rem",
    size = iconSizes.SM,
    style,
    ...props
}: ActionArrowRightProps) {
    return (
        <Circular radius={radius} style={{ backgroundColor: themeColors.background, ...style }} {...props}>
            <ArrowRight strokeWidth={strokeWidth.normal} size={size} color={themeColors.neutralBlack} />
        </Circular>
    );
}
