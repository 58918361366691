"use client";

import { signOut } from "@/hooks/user/auth";
import { useCurrentUser } from "@/hooks/user/useCurrentUser";
import { SubscriptionType } from "@knowt/syncing/graphql/schema";
import { Moon, Puzzle, Settings, Sparkles, User } from "lucide-react";
import { toast } from "react-hot-toast";
import ConditionalTooltip from "./wrappers/ConditionalTooltip";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import WithOptionsMenu, { WithMenuOption } from "@/components/WithOptionsMenu";
import UserAvatar from "@/components/userCardComponents/UserAvatar";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { iconSizes, strokeWidth } from "@/utils/iconProps";
import { useTheme } from "@/utils/theme";
import { themeColors } from "@/utils/themeColors";
import { initializeAllWalkthrough, removeAllWalkthrough } from "@/features/GuidedWalkthrough";
import { canShowAds } from "@knowt/syncing/hooks/user/subscriptions";

const TabletSmartphoneIcon = () => {
    return (
        <svg
            width={iconSizes.SM}
            height={iconSizes.SM}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={strokeWidth.normal}
            strokeLinecap="round"
            strokeLinejoin="round"
            style={{ marginRight: "1rem" }}
            className="lucide lucide-tablet-smartphone">
            <rect width="10" height="14" x="3" y="8" rx="2" />
            <path d="M5 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-2.4" />
            <path d="M8 18h.01" />
        </svg>
    );
};

const ProfileCircleWithMenu = ({ pictureUrl }: { pictureUrl?: string | null }) => {
    const { isDarkMode, changeTheme } = useTheme();
    const { mdDown } = useBreakPoints({ md: 1100 });
    const { user, subscriptionType, isSubscriptionCancelling, resubscribe, organization } = useCurrentUser();

    const subscriptionOptionText = isSubscriptionCancelling
        ? "Resubscribe"
        : !subscriptionType || subscriptionType === SubscriptionType.BASIC
        ? "Remove Ads"
        : "Upgrade";

    const subscriptionOption: WithMenuOption = mdDown
        ? isSubscriptionCancelling
            ? {
                  label: subscriptionOptionText,
                  onClick: async () => {
                      await toast.promise(resubscribe(), {
                          loading: "Restarting your subscription...",
                          success: "Success!",
                          error: "Please try again later.",
                      });
                  },
              }
            : {
                  label: subscriptionOptionText,
                  href: "/plans",
              }
        : null;

    const options: WithMenuOption[] = [
        {
            label: "Profile",
            node: (
                <FlexRowAlignCenter style={{ width: "100%", marginBottom: "0", height: "5.8rem" }}>
                    <FlexRowAlignCenter style={{ gap: "1rem", width: "100%" }}>
                        <UserAvatar
                            radius="4rem"
                            src={user?.pictureUrl || pictureUrl}
                            alt={user?.Name || user?.username}
                        />

                        <FlexColumn>
                            <span className={"ellipsisText"} style={{ fontSize: "1.5rem", fontWeight: "600" }}>
                                {user?.Name || "Unnamed"}
                            </span>
                            <span className={"ellipsisText"} style={{ fontSize: "1.2rem", fontWeight: "500" }}>
                                @{user?.username}
                            </span>
                        </FlexColumn>
                    </FlexRowAlignCenter>
                </FlexRowAlignCenter>
            ),
            href: `/u/${user?.username}`,
            menuItemProps: {
                sx: {
                    marginTop: "-0.8rem",
                    borderBottom: `0.1rem solid ${themeColors.line}`,
                    paddingBottom: "0rem",
                },
            },
        },
        {
            // TODO: remove this options once 'guided walkthrough PR' merged
            label: "Initialize Walkthrough LS",
            node: (
                <FlexRowAlignCenter>
                    <Settings size={iconSizes.SM} style={{ marginRight: "1rem" }} />
                    Initialize Walkthrough LS
                </FlexRowAlignCenter>
            ),
            onClick: () => initializeAllWalkthrough(),
        },
        {
            label: "Settings",
            node: (
                <FlexRowAlignCenter>
                    <Settings size={iconSizes.SM} style={{ marginRight: "1rem" }} />
                    Settings
                </FlexRowAlignCenter>
            ),
            href: `/settings`,
        },
        !organization?.dpa
            ? {
                  label: "Profile",
                  node: (
                      <FlexRowAlignCenter>
                          <User size={iconSizes.SM} style={{ marginRight: "1rem" }} />
                          Profile
                      </FlexRowAlignCenter>
                  ),
                  href: `/u/${user?.username}`,
              }
            : null,
        {
            label: `Dark/Light mode enabler`,
            node: (
                <FlexRowAlignCenter>
                    <Moon size={iconSizes.SM} style={{ marginRight: "1rem" }} />
                    Enable {isDarkMode ? "Light" : "Dark"} Mode
                </FlexRowAlignCenter>
            ),
            onClick: () => changeTheme(isDarkMode ? "light" : "dark"),
        },
        {
            label: `check ai usage`,
            node: (
                <FlexRowAlignCenter>
                    <Sparkles size={iconSizes.SM} style={{ marginRight: "1rem" }} />
                    Check AI Usage
                </FlexRowAlignCenter>
            ),
            href: "/settings/#aiUsage",
        },
        {
            label: "Install mobile app",
            node: (
                <FlexRowAlignCenter>
                    <TabletSmartphoneIcon />
                    Install Mobile App!
                </FlexRowAlignCenter>
            ),
            href: "/mobile",
        },
        {
            label: "Download Chrome Extension",
            node: (
                <FlexRowAlignCenter>
                    <Puzzle size={iconSizes.SM} style={{ marginRight: "1rem" }} />
                    Download Chrome Extension
                </FlexRowAlignCenter>
            ),
            href: "https://chrome.google.com/webstore/detail/knowt-quizlet-import-ai-n/akegecpdcdbkjioddaingaedacjgfjhm",
        },
        {
            label: "Having Issues?",
            onClick: () => window.open("/contact", "_blank"),
        },
        {
            label: "Check out what's new",
            onClick: () => window.open("/updates", "_blank"),
        },
        {
            label: "Privacy",
            onClick: () => window.open("/privacy", "_blank"),
        },
        {
            label: "Terms",
            onClick: () => window.open("/terms", "_blank"),
        },
        ...(canShowAds({ user, organization }) ? [subscriptionOption] : []),
        {
            label: "Log Out",
            onClick: async () => {
                // initializeAllWalkthrough();
                removeAllWalkthrough();
                await signOut();
                window.location.href = "/goodbye";
            },
            menuItemProps: {
                sx: {
                    color: "#FD7A7A",
                    borderTop: `0.1rem solid ${themeColors.line}`,
                    paddingBottom: "1.2rem",
                    marginBottom: "-8px", // menu's padding bottom
                },
            },
        },
    ].filter(option => !!option) as WithMenuOption[];

    return (
        <WithOptionsMenu
            menuItemProps={{ sx: { padding: "1rem 1.6rem" } }}
            menuProps={{
                PaperProps: {
                    sx: {
                        marginTop: "1rem",
                        borderRadius: "1rem",
                        backgroundColor: themeColors.background,
                        fontSize: "1.4rem",
                        width: "26.7rem",
                    },
                },
            }}
            options={options}>
            {({ openMenu }) => (
                <ConditionalTooltip tooltip={"change settings"}>
                    <div onClick={openMenu}>
                        <UserAvatar
                            style={{ cursor: "pointer" }}
                            radius="3.8rem"
                            src={user?.pictureUrl || pictureUrl || ""}
                            alt={user?.Name || user?.username || "U"}
                        />
                    </div>
                </ConditionalTooltip>
            )}
        </WithOptionsMenu>
    );
};

export default ProfileCircleWithMenu;
