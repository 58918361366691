"use client";

import { CircularButtonProps } from "@/components/CircularButton";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { useAuthSlideContextSelector } from "@/features/Auth";
import { themeColors } from "@/utils/themeColors";

export const NavbarSigninBtn = ({ sx, ...btnProps }: Omit<CircularButtonProps, "onClick">) => {
    const openAuthSlide = useAuthSlideContextSelector(state => state.openAuthSlide);

    return (
        <CircularRectTextButton
            key="login"
            onClick={() => {
                openAuthSlide({ showLoginPage: true });
            }}
            sx={{
                border: `1px solid ${themeColors.neutralBlack}`,
                height: "37px",
                width: "8rem",
                borderRadius: "4rem",
                fontSize: "1.5rem",
                "&:hover": {},
                ...sx,
            }}
            {...btnProps}>
            Login
        </CircularRectTextButton>
    );
};

export const NavbarSignupBtn = ({ sx, ...btnProps }: Omit<CircularButtonProps, "onClick">) => {
    const openAuthSlide = useAuthSlideContextSelector(state => state.openAuthSlide);

    return (
        <CircularRectTextButton
            key="sign-up"
            onClick={() => openAuthSlide({ showSignupPage: true })}
            {...btnProps}
            sx={{
                ...sx,
                backgroundColor: "#50d2c2",
                color: themeColors.primaryDark,
                height: "37px",
                width: "14rem",
                borderRadius: "4rem",
                fontSize: "1.5rem",
            }}>
            Get started
        </CircularRectTextButton>
    );
};
