import { usePathname, useSearchParams } from "next/navigation";
import { useCallback } from "react";

export const useSafeQueryNavigation = () => {
    const searchParams = useSearchParams();
    const pathname = usePathname();

    const reroute = useCallback(
        (type: "push" | "replace", action: "add" | "remove" | "replace") =>
            (params: { [key in string]: string | null | undefined }) => {
                let current = new URLSearchParams();
                if (searchParams) {
                    // now you got a read/write object
                    current = new URLSearchParams(Array.from(searchParams?.entries())); // -> has to use this form
                }
                if (action === "replace") {
                    // if we are replacing, we need to clear the current params
                    current = new URLSearchParams();
                }
                Object.entries(params).forEach(([key, value]) => {
                    if (action !== "remove" && value) {
                        current.set(key, value);
                    } else {
                        current.delete(key);
                    }
                });

                // cast to string
                const search = current.toString();
                const query = search ? `?${search}` : "";

                //prevents browser from storing history with each change:
                window.history[type === "replace" ? "replaceState" : "pushState"]({}, pathname, query);
            },
        [pathname, searchParams]
    );

    return {
        addParamsPush: (params: { [key in string]: string | null | undefined }) => reroute("push", "add")(params),
        addParamsReplace: (params: { [key in string]: string | null | undefined }) => reroute("replace", "add")(params),
        removeParamsPush: (params: string[]) =>
            reroute("push", "remove")(params.reduce((acc, curr) => ({ ...acc, [curr]: null }), {})),
        removeParamsReplace: (params: string[]) =>
            reroute("replace", "remove")(params.reduce((acc, curr) => ({ ...acc, [curr]: null }), {})),
        setParamsPush: (params: { [key in string]: string | null | undefined }) => reroute("push", "replace")(params),
        setParamsReplace: (params: { [key in string]: string | null | undefined }) =>
            reroute("push", "replace")(params),
    };
};
