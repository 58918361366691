// https://github.com/spencermountain/spacetime/blob/14fd1eff1fc54cecfc55bd85148c92115fa6adb8/src/timezone/guessTz.js

const safeIntlTZ = (): string | null => {
    if (typeof Intl === "undefined" || typeof Intl.DateTimeFormat === "undefined") {
        return null;
    }

    const format = Intl.DateTimeFormat();
    if (typeof format === "undefined" || typeof format.resolvedOptions === "undefined") {
        return null;
    }

    const timezone = format.resolvedOptions().timeZone;
    if (!timezone) return null;

    return timezone;
};

const getLocalTZ = () => {
    const fallbackTZ = "unknown";
    return safeIntlTZ() || fallbackTZ;
};

const timezone = { getLocalTZ };

export default timezone;
