"use client";
import React, { useCallback, useState } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import { Class, FlashcardSet, Folder, Media, Note, UserDetails } from "@knowt/syncing/graphql/schema";
import { objectWithout } from "@knowt/syncing/utils/dataCleaning";
import { SetState } from "@knowt/syncing/types/common";
import { STORAGE_KEYS } from "@/config/constants";
import { ALL_ITEM_TYPES, UserContentItemType, UserContentSortSettings } from "../types";
import useCookieWithFallback from "@/hooks/useCookieWithFallback";

export type UserContentManagementContextProps = {
    sort: UserContentSortSettings;
    setSort: SetState<UserContentSortSettings>;
    //
    isSelectMode: boolean;
    setIsSelectMode: SetState<boolean>;
    itemTypesToShow: UserContentItemType[];
    setItemTypesToShow: SetState<UserContentItemType[]>;
    //
    selectedItems: Record<string, Note | FlashcardSet | Media | Folder>;
    setSelectedItems: SetState<Record<string, Note | FlashcardSet | Media | Folder>>;
    toggleSelectedItem: (id: string, item: Note | FlashcardSet | Media | Folder) => void;
    //
    serverFolders?: Record<string, Folder>;
    serverNotes?: Record<string, Note>;
    serverFlashcardSets?: Record<string, FlashcardSet>;
    serverMedias?: Record<string, Media>;
    serverClasses?: Record<string, Class>;
    serverUser: UserDetails | null;
};

const UserContentManagementContext = createContext<UserContentManagementContextProps | null>(null);

export const UserContentManagementContextProvider = ({
    children,
    serverFolders,
    serverNotes,
    serverFlashcardSets,
    serverMedias,
    serverUser,
    serverClasses,
    sortValue,
    classId,
}: {
    children: React.ReactNode;
    serverFolders?: Record<string, Folder>;
    serverNotes?: Record<string, Note>;
    serverFlashcardSets?: Record<string, FlashcardSet>;
    serverMedias?: Record<string, Media>;
    serverClasses?: Record<string, Class>;
    serverUser: UserDetails | null;
    sortValue: UserContentSortSettings;
    classId?: string;
}) => {
    const [sort, setSort] = useCookieWithFallback<UserContentSortSettings>({
        key: classId
            ? STORAGE_KEYS.CLASSES_PAGE_SORT_AND_DIRECTION(classId)
            : STORAGE_KEYS.HOME_PAGE_SORT_AND_DIRECTION,
        fallback: sortValue || { by: "LAST_UPDATED", direction: "DESCENDING" },
    });

    // Don't check off "Saved Only" by default
    const [itemTypesToShow, setItemTypesToShow] = useState<UserContentItemType[]>(ALL_ITEM_TYPES.slice(0, -1));

    const [isSelectMode, setIsSelectMode] = useState(false);
    const [selectedItems, setSelectedItems] = useState<Record<string, Note | FlashcardSet | Folder | Media>>({});

    const toggleSelectedItem = useCallback((id: string, item: Note | FlashcardSet | Folder) => {
        setSelectedItems(currentSelectedItems => {
            if (!currentSelectedItems[id]) {
                return { ...currentSelectedItems, [id]: item };
            } else {
                return objectWithout(currentSelectedItems, id);
            }
        });
    }, []);

    return (
        <UserContentManagementContext.Provider
            value={{
                sort,
                setSort,
                itemTypesToShow,
                setItemTypesToShow,
                //
                isSelectMode,
                setIsSelectMode,
                selectedItems,
                setSelectedItems,
                toggleSelectedItem,
                //
                serverNotes,
                serverFolders,
                serverMedias,
                serverFlashcardSets,
                serverUser,
                serverClasses,
            }}>
            {children}
        </UserContentManagementContext.Provider>
    );
};

export const useUserContentManagementContextSelector = <T,>(
    selector: (state: UserContentManagementContextProps) => T
) => useContextSelector(UserContentManagementContext, selector);
