import { FlashcardSet, Folder, Media, Note } from "@knowt/syncing/graphql/schema";

export type UserContentItem = (Note | FlashcardSet | Media) & { isBookmarked?: boolean };

export type UserContentItemType = "NOTE" | "FLASHCARD_SET" | "VIDEO" | "PDF" | "POWERPOINT" | "EXCEL" | "PINNED_ONLY";

export type UserContent = Note | FlashcardSet | Folder | Media;

export type UserContentSortSettings = {
    by: "LAST_UPDATED" | "NAME" | "CREATED" | "ADDED";
    direction: "ASCENDING" | "DESCENDING";
};

export const ALL_ITEM_TYPES: UserContentItemType[] = [
    "NOTE",
    "FLASHCARD_SET",
    "VIDEO",
    "PDF",
    "POWERPOINT",
    "EXCEL",
    "PINNED_ONLY",
];
