import { useCallback, useEffect } from "react";

export const useExitFunction = (_save: () => Promise<void>) => {
    const save = useCallback(async () => {
        await _save();

        return;
    }, [_save]);

    useEffect(() => {
        // Save on window blur
        window.addEventListener("beforeunload", save);

        return () => {
            window.removeEventListener("beforeunload", save);
        };
    }, [save]);
};
