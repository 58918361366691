import packageInfo from "@/package.json";
import { enableAnalytics } from "@/config/deployConstants";

const isMixpanelInitialized = () => window && (window as any).mixpanel && (window as any).mixpanel.track;

export const MIXPANEL_DEBUG_MODE = false;

export const MIXPANEL_PROJECT_ID = packageInfo.development
    ? "db3f8de810a1c3e573663a71617deb88"
    : "34142dcacfcd7635e660802a2743e57c";

const Mixpanel = {
    init: (ignore_dnt: false) => {
        try {
            if (enableAnalytics) {
                (window as any).mixpanel.init(MIXPANEL_PROJECT_ID, {
                    debug: MIXPANEL_DEBUG_MODE,
                    ignore_dnt: true,
                });
            } else {
                (window as any).mixpanel.init(MIXPANEL_PROJECT_ID, {
                    debug: MIXPANEL_DEBUG_MODE,
                    ignore_dnt,
                });
            }
        } catch (e) {
            // pass
        }
    },
    identify: (id: string) => {
        try {
            if (isMixpanelInitialized() && enableAnalytics) {
                (window as any).mixpanel.identify(id);
            }
        } catch (e) {
            // pass
        }
    },
    alias: (id: string) => {
        try {
            if (isMixpanelInitialized()) {
                (window as any).mixpanel.alias(id);
            }
        } catch (e) {
            // pass
        }
    },
    track: (
        event: string,
        props: {
            [key: string]: unknown;
        } = {}
    ) => {
        try {
            if (!isMixpanelInitialized()) return;
            const params = new URL(window.location.href).searchParams;
            const paramsObj = {};
            for (const [key, value] of params) {
                paramsObj[key] = value;
            }

            const additionalInfo = {
                appVersion: packageInfo.version,
                development: packageInfo.development,
                pathname: window?.location?.pathname,
                deviceInfo: window?.clientInformation?.platform,
                screenHeight: window?.screen?.height,
                screenWidth: window?.screen?.width,
                queryParams: paramsObj,
                httpreferrer: document?.referrer,
            };

            if (enableAnalytics) {
                (window as any).mixpanel.track(event, { ...props, ...additionalInfo, appVersion: packageInfo.version });
            }
        } catch (e) {
            // pass
        }
    },
    people: {
        set: (props: { [key: string]: unknown }) => {
            try {
                if (isMixpanelInitialized() && enableAnalytics) {
                    (window as any).mixpanel.people.set(props);
                }
            } catch (e) {
                // pass
            }
        },
    },
    reset: () => {
        try {
            if (isMixpanelInitialized()) {
                (window as any).mixpanel.reset();
            }
        } catch (e) {
            // pass
        }
    },
};

export default Mixpanel;
