import { forwardRef } from "react";
import ConditionalTooltip from "../wrappers/ConditionalTooltip";
import { FlexRowAlignCenter } from "@/components/Flex";

export type CircularProps = {
    radius?: string;
    tooltip?: string;
} & React.HTMLAttributes<HTMLSpanElement>;

/**
 * Nothing special, just a circular background
 */
const Circular = forwardRef(({ children, style, radius, tooltip, ...spanProps }: CircularProps, ref: any) => {
    return (
        <ConditionalTooltip tooltip={tooltip}>
            <FlexRowAlignCenter
                ref={ref}
                as={"span"}
                className="bold"
                style={{
                    fontFamily: "var(--knowt-font-name)",
                    justifyContent: "center",
                    borderRadius: 9999,
                    height: radius,
                    width: radius,
                    flexGrow: 0,
                    flexShrink: 0,
                    ...style,
                }}
                {...spanProps}>
                {children}
            </FlexRowAlignCenter>
        </ConditionalTooltip>
    );
});

Circular.displayName = "Circular";

export default Circular;
