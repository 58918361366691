"use client";
import { usePathname } from "next/navigation";
import styles from "../navbar.module.css";
import { ConditionalLink } from "@/components/ConditionalLink";

type NavbarLinkProps = {
    href?: string;
    text: string;
    className?: string;
    forceDarkTheme?: boolean;
};

export const NavbarLink = ({ href, text }: NavbarLinkProps) => {
    const pathname = usePathname();

    return (
        <ConditionalLink
            href={href as string}
            condition={!!href}
            className={styles.actionableText}
            style={{
                fontWeight: "600",
                color: pathname === href ? "#50d2c2" : undefined,
                fontSize: "1.5rem",
            }}>
            {text}
        </ConditionalLink>
    );
};

export default NavbarLink;
