import { useCallback, useEffect, useState } from "react";
import {
    checkLSHasWalkthroughTaken,
    getLSWalkthroughCurrStep,
    getRemainingWalkthrough,
    removeLSWalkthroughTaken,
    updateLSWalkthroughSteps,
} from "../utils";

export const useGuidedWalkthrough = ({ label }: { label: string }) => {
    const [stepIdx, setStepIdx] = useState(0);
    useEffect(() => {
        if (!hasWalkthroughTaken()) {
            setStepIdx(getLSWalkthroughCurrStep({ label }));
        }
        // eslint-disable-next-line
    }, []);

    const [isWalkthroughActive, setIsWalkthroughActive] = useState(false);

    const activateWalkthrough = useCallback(({ timeout = 500 }: { timeout?: number }) => {
        setTimeout(() => {
            setIsWalkthroughActive(true);
        }, timeout);
    }, []);

    const hasWalkthroughTaken = useCallback(() => {
        return checkLSHasWalkthroughTaken({ label });
    }, [label]);

    const doneWalkthrough = useCallback(() => {
        removeLSWalkthroughTaken({ label });
        setIsWalkthroughActive(false);
    }, [label]);

    const doneAStep = useCallback(
        (idx: number) => {
            const remainingWalkthrough = getRemainingWalkthrough();
            const newSteps = remainingWalkthrough[label]?.filter((step: number) => step !== idx) ?? [];
            updateLSWalkthroughSteps({ label, steps: newSteps });
        },
        [label]
    );

    const nextAStep = useCallback(() => {
        doneAStep(stepIdx);
        setStepIdx(stepIdx + 1);
    }, [doneAStep, stepIdx, setStepIdx]);

    const backAStep = useCallback(() => {
        setStepIdx(stepIdx - 1);
    }, [setStepIdx, stepIdx]);

    return {
        isWalkthroughActive,
        activateWalkthrough,
        hasWalkthroughTaken,
        doneWalkthrough,
        doneAStep,
        /** sync last remaining steps from LS */
        stepIdx,
        /** next a step and sync it with LS */
        nextAStep,
        /** go back one step */
        backAStep,
    };
};
