"use client";

import { Menu } from "lucide-react";

import dynamic from "next/dynamic";
import { useState } from "react";
import NavbarCircularBtn from "./styled/NavbarCircularBtn";
import LazyLoaded from "@/components/LazyLoaded";
import { iconSizes } from "@/utils/iconProps";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";

const Drawer = dynamic(() => import("@/components/navigation/Drawer"), { ssr: false });

const toggleDrawerEventHandler = setter => event => {
    if (event?.type === "keydown" && (event?.key === "Tab" || event?.key === "Shift")) {
        return;
    }
    setter(prev => !prev);
};

const HamburgerBtnWithHandler = () => {
    const { mdDown } = useBreakPoints();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = toggleDrawerEventHandler(setDrawerOpen);

    return (
        <>
            <NavbarCircularBtn aria-label="open menu" onClick={toggleDrawer}>
                <Menu size={iconSizes.SM + 3} />
            </NavbarCircularBtn>
            <LazyLoaded key="drawer" load={mdDown}>
                <Drawer open={drawerOpen} toggleDrawer={toggleDrawer} />
            </LazyLoaded>
        </>
    );
};

export default HamburgerBtnWithHandler;
