import React, { forwardRef } from "react";
import Box, { BoxProps } from "@mui/material/Box";

export type RoundInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    sx?: BoxProps["sx"];
    component?: BoxProps["component"];
};

const RoundInput = ({ sx, component, ...inputProps }: RoundInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    return (
        <Box
            component={component || "input"}
            ref={ref}
            sx={{
                font: "var(--knowt-font-name)",
                fontSize: "1.6rem",
                color: "var(--color-neutral-black)",
                backgroundColor: "var(--color-search-bar)",
                outline: "none",
                borderRadius: 999,
                paddingLeft: "1.6rem",
                paddingRight: "1rem",
                border: "2px solid",
                width: "100%",
                ...sx,
            }}
            {...inputProps}
        />
    );
};

export default forwardRef<HTMLInputElement, RoundInputProps>(RoundInput);
