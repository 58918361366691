"use client";

import { Bell } from "lucide-react";
import dynamic from "next/dynamic";
import { useRef, useState } from "react";
import LazyLoaded from "@/components/LazyLoaded";
import CircularOutlineIcon from "@/components/CircularButton/styled/CircularOutlineIcon";
import styles from "@/components/notifications/notifications.module.css";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { iconSizes } from "@/utils/iconProps";

const NotificationComponent = dynamic(() => import("@/components/notifications/NotificationsMenu"));
const NotificationAnimation = dynamic(() => import("./styled/NotificationAnimation"));

const NotificationBtnWithHandler = () => {
    const { smDown } = useBreakPoints();

    const buttonRef = useRef<HTMLElement>(null);

    const [notificationsOpen, setNotificationsOpen] = useState(false);

    const calculatePosition = () => {
        if (buttonRef.current) {
            const rect = buttonRef.current.getBoundingClientRect();
            return {
                top: rect.bottom + 10,
                right: window.innerWidth - (rect.right ?? 0),
            };
        }
        return { top: 0, left: 0 };
    };

    return (
        <>
            <CircularOutlineIcon
                ref={buttonRef}
                onClick={() => {
                    Mixpanel.track("Notification Menu - Clicked");
                    setNotificationsOpen(true);
                }}
                Icon={Bell}
                size={iconSizes.SM}
                tooltip={"view notifications"}
            />

            <LazyLoaded load={notificationsOpen}>
                <NotificationAnimation
                    timeout={undefined}
                    isOpen={notificationsOpen}
                    onClose={() => setNotificationsOpen(false)}
                    containerClassName={styles.notificationAnimationContainer}
                    containerStyle={{
                        top: calculatePosition().top,
                        right: calculatePosition().right,
                    }}
                    applyResponsive={smDown}>
                    <NotificationComponent
                        isOpen={notificationsOpen}
                        applyResponsive={smDown}
                        close={() => setNotificationsOpen(false)}
                    />
                </NotificationAnimation>
            </LazyLoaded>
        </>
    );
};

export default NotificationBtnWithHandler;
