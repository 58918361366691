import { MrMiyagi, Ring, ThreeBody } from "@uiball/loaders";
import React from "react";
import { themeColors } from "@/utils/themeColors";

type CenteredSpinnerProps = {
    className?: string;
    label?: string;
    style?: React.CSSProperties;
    size?: number;
    color?: string;
};

const LoadingAnimation = ({ className, label, style, size = 40, color }: CenteredSpinnerProps) => {
    return (
        <div
            className={className}
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2rem",
                color: color ?? themeColors.inverted,
                ...style,
            }}>
            <MrMiyagi speed={1.75} size={size} color={color ?? themeColors.inverted} />
            {label && <div style={{ fontSize: "2rem", fontWeight: "600" }}>{label}</div>}
        </div>
    );
};

export const RingLoadingAnimation = ({ className, label, style, size = 40, color }: CenteredSpinnerProps) => {
    return (
        <div
            className={className}
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2rem",
                color: color ?? themeColors.inverted,
                ...style,
            }}>
            <Ring speed={1.75} size={size} color={color ?? themeColors.inverted} />
            {label && <div style={{ fontSize: "2rem", fontWeight: "bold" }}>{label}</div>}
        </div>
    );
};

export const ThreeBodyLodingAnimation = ({ className, label, style, size = 40, color }: CenteredSpinnerProps) => {
    return (
        <div
            className={className}
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "2rem",
                color: color ?? themeColors.inverted,
                ...style,
            }}>
            <ThreeBody speed={1} size={size} color={color ?? themeColors.inverted} />
            {label && <div style={{ fontSize: "2rem", fontWeight: "600" }}>{label}</div>}
        </div>
    );
};

export default LoadingAnimation;
