import { ChevronDown } from "lucide-react";
import React from "react";
// eslint-disable-next-line no-restricted-imports
import type { SxProps } from "@mui/material";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import WithOptionsMenu from "@/components/WithOptionsMenu";
import { iconSizes } from "@/utils/iconProps";
import noop from "@knowt/syncing/utils/noop";

export type SelectProps<T extends { label: string; value: unknown }> = {
    options: T[];
    selected: T;
    btnSx?: SxProps;
    menuSx?: SxProps;
    onChange: (e: T) => void;
    disabled?: boolean;
    /** In case you want to customize the label. By default it will just display a plain text of `option.label` */
    renderLabel?: (props: T) => React.ReactNode;
    /** In case you want to put icon besides the label. By default it's a chevron. Pass `null` to remove the icon */
    adornment?: React.ReactNode;
    /** Position of the menu animation from what point to which direction it will be opened to */
    transitionOrigin?: {
        anchorOrigin: { vertical: "bottom" | "top" | "center"; horizontal: "right" | "left" | "center" };
        transformOrigin: { vertical: "bottom" | "top" | "center"; horizontal: "right" | "left" | "center" };
    };
};

const Select = <T extends { label: string; value: unknown }>({
    options,
    btnSx,
    menuSx,
    transitionOrigin = {
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
        transformOrigin: { vertical: "top", horizontal: "center" },
    },
    onChange,
    disabled,
    selected,
    adornment = <ChevronDown size={iconSizes.SM} fill={"currentColor"} strokeWidth={0} />,
    renderLabel = ({ label }) => label,
}: SelectProps<T>) => {
    return (
        <WithOptionsMenu
            menuProps={{
                PaperProps: { sx: { borderRadius: "1.5rem", width: "10rem", marginTop: "1rem", ...menuSx } },
                anchorOrigin: transitionOrigin.anchorOrigin,
                transformOrigin: transitionOrigin.transformOrigin,
            }}
            options={options.map(o => ({
                label: o.label,
                onClick: () => {
                    onChange(o);
                },
            }))}>
            {({ openMenu }) => {
                return (
                    <CircularRectTextButton
                        onClick={e => (!disabled ? openMenu(e) : noop)}
                        type="button"
                        sx={{
                            borderRadius: "unset",
                            backgroundColor: "transparent",
                            padding: "1rem 1.5rem",
                            "&:hover, &:active": {
                                boxShadow: "inset 0 0 0 10em rgba(0, 0, 0, 0.08)",
                            },
                            ...btnSx,
                        }}
                        tooltip={disabled ? "locked" : "select an option"}>
                        {renderLabel(selected)}
                        {adornment}
                    </CircularRectTextButton>
                );
            }}
        </WithOptionsMenu>
    );
};

export default Select;
