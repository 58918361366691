import { strokeWidth } from "@/utils/iconProps";
import { Circle, X } from "lucide-react";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import ClickableText from "@/components/styled/button/ClickableText";
import { themeColors } from "@/utils/themeColors";
import { TooltipRenderProps } from "react-joyride";
import { WalkthroughStep } from "../types";
import CircularButton from "@/components/CircularButton";

const WalkthroughTooltip = ({
    backProps,
    index,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
}: TooltipRenderProps & { step: WalkthroughStep }) => {
    const numOfSteps = step.numOfSteps || 1;

    const renderStepIndicator = () => {
        if (numOfSteps < 2) return null;

        return (
            <FlexRowAlignCenter style={{ gap: "1rem" }}>
                {new Array(step.numOfSteps).fill(1).map((_, i: number) => (
                    <Circle
                        key={i}
                        size={8}
                        color={index === i ? themeColors.neutralWhite : themeColors.neutral2}
                        fill={index === i ? themeColors.neutralWhite : themeColors.neutral2}
                    />
                ))}
            </FlexRowAlignCenter>
        );
    };

    return (
        <FlexColumn
            style={{
                padding: "2.4rem",
                color: themeColors.neutralWhite,
                backgroundColor: themeColors.neutral3,
                borderRadius: "1rem",
                maxWidth: "36rem",
                ...step.styles,
            }}
            {...tooltipProps}>
            <FlexRowAlignCenter style={{ gap: "2rem" }}>
                {step?.Image && step.Image}
                <FlexColumn>
                    <FlexRowAlignCenter style={{ marginBottom: "0.8rem" }}>
                        <div className="bodyBold2" style={{ color: themeColors.neutralWhite }}>
                            {step.title}
                        </div>
                        <CircularButton
                            radius={"2.5rem"}
                            {...skipProps}
                            sx={{
                                marginLeft: "auto",
                                alignSelf: "flex-start",
                                backgroundColor: "transparent",
                                "&:hover": {},
                            }}>
                            <X strokeWidth={strokeWidth.normal} size={20} color={themeColors.neutralWhite} />
                        </CircularButton>
                    </FlexRowAlignCenter>
                    <div className="secondaryText1" style={{ color: themeColors.neutralWhite }}>
                        {step.content}
                    </div>
                </FlexColumn>
            </FlexRowAlignCenter>
            <FlexRowAlignCenter>
                {renderStepIndicator()}
                <FlexRowAlignCenter style={{ gap: "2.4rem", marginLeft: "auto" }}>
                    {index > 0 && numOfSteps > 1 && (
                        <ClickableText style={{ color: themeColors.neutralWhite, fontSize: "1.4rem" }} {...backProps}>
                            Back
                        </ClickableText>
                    )}
                    {numOfSteps > 1 && (
                        <CircularRectTextButton
                            sx={{
                                width: "5.4rem",
                                height: "2.8rem",
                                color: themeColors.neutralBlack,
                                backgroundColor: themeColors.neutralWhite,
                                borderRadius: "0.5rem",
                            }}
                            {...primaryProps}
                            onClick={step.onNext ? step.onNext : primaryProps.onClick}>
                            {numOfSteps > 1 && index === numOfSteps - 1 ? "Done" : "Next"}
                        </CircularRectTextButton>
                    )}
                </FlexRowAlignCenter>
            </FlexRowAlignCenter>
        </FlexColumn>
    );
};

export default WalkthroughTooltip;
