import { useEffect, useState } from "react";

type LazyLoadedProps = {
    load: boolean;
    children: JSX.Element;
};

// FOR THIS COMPONENT TO WORK AS INTENDED, THE `children` PROP
// MUST BE A DYNAMICALLY IMPORTED REACT COMPONENT, USING 'next/dynamic'
const LazyLoaded = ({ load, children }: LazyLoadedProps) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (load) setIsLoaded(true);
    }, [load]);

    // once `isLoaded` became `true`, the `children` chunk
    // will be downloaded (supposing it wasn't already)
    return isLoaded ? children : null;
};

export default LazyLoaded;
