"use client";

import { Ban, Check } from "lucide-react";
import { toast } from "react-hot-toast";
import timezone from "@/utils/timezone";
import Select from "@/components/Select";
import { spacing } from "@/utils/spacing";
import { iconSizes } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";
import { report } from "@/utils/analytics/logging";
import { useState, useEffect, useMemo } from "react";
import { LocalAuthUser } from "@knowt/syncing/hooks/user/types";
import RoundInput from "@/components/styled/input/RoundInput";
import { useCombinedState } from "@/utils/hooks/useCombinedState";
import { Flex, FlexColumn, FlexRowAlignCenter } from "@/components/Flex";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { callIsUsernameAvailable } from "@knowt/syncing/hooks/user/graphqlUtils";
import CircularOutlineIcon from "@/components/CircularButton/styled/CircularOutlineIcon";
import BorderedInputDatePicker from "@/components/DatePicker/styled/BorderedInputDatePicker";
import CircularRectTextButton from "@/components/CircularButton/styled/CircularRectTextButton";
import { isUnderAge, updateUserInfo, updateUserUsername } from "@knowt/syncing/hooks/user/utils";
import { assertTruthy } from "@knowt/syncing/utils/assertions";

const BasicUserInfoForm = ({ onNext, serverUser }: { onNext: () => void; serverUser: LocalAuthUser }) => {
    const { user } = useCurrentUser({
        fallbackData: serverUser,
    });

    const [isLoading, setIsLoading] = useState(false);

    const [profileForm, updateProfileForm] = useCombinedState<{
        username: string;
        birthday: Date | null;
        accountType: string;
    }>({
        username: serverUser.user.username ?? "",
        birthday: serverUser.user.birthday ? new Date(serverUser.user.birthday) : null,
        accountType: serverUser.user.accountType ?? "Student",
    });

    const isUnder13 = useMemo(() => {
        const isUnder = isUnderAge(profileForm.birthday, 13);
        if (isUnder) {
            updateProfileForm({ accountType: "Student" });
        }

        return isUnder;
    }, [profileForm.birthday, updateProfileForm]);

    const userNameDisabled = !!user?.username;

    const [isUsernameAvailable, setIsUsernameAvailable] = useState<boolean | null | undefined>(false);

    useEffect(() => {
        if (!profileForm.username || profileForm.username === user?.username) {
            return;
        }

        callIsUsernameAvailable(profileForm.username).then(isAvailable => {
            setIsUsernameAvailable(isAvailable);
        });
    }, [user?.username, profileForm.username]);

    const handleSubmit = async () => {
        if (isLoading) return;

        setIsLoading(true);

        try {
            await validateInput();
            await Promise.all([updateUsername(), updateOtherUserData()]);

            // if (isUnder13 && !organization?.dpa) {
            //     toast.error("Please contact your school about using Knowt!");
            //     signOut();
            //     return router.replace(`/block?type=school&reason=under13`);
            // }

            onNext();
        } catch (error) {
            report(error, "handleSubmit InitialAccountSetupPopup");
        } finally {
            setIsLoading(false);
        }
    };

    const validateInput = async () => {
        if (!profileForm.username || !profileForm.birthday || !profileForm.accountType) {
            toast.error("Please fill out all fields");
            throw new Error("Please fill out all fields");
        }

        if (profileForm.username.includes(" ")) {
            toast.error("Sorry you can't have any spaces in your username");
            throw new Error("Sorry you can't have any spaces in your username");
        }

        if (profileForm.username.toLowerCase() !== profileForm.username) {
            toast.error("Please use lower case letters for username");
            throw new Error("Please use lower case letters for username");
        }

        if (profileForm.username.length < 3) {
            toast.error("Please use at least 3 letters for your username");
            throw new Error("Please use at least 3 letters for your username");
        }

        if (!user?.birthday && !profileForm.birthday?.toISOString()) {
            toast.error("Please enter your birthdate");
            throw new Error("Please enter your birthdate");
        }

        const isAvailable = await callIsUsernameAvailable(profileForm.username);

        if (!isAvailable && profileForm.username !== user?.username) {
            toast.error("Sorry that username is already being used");
            throw new Error("Sorry that username is already being used");
        }
    };

    const updateUsername = async () => {
        assertTruthy(profileForm.username);
        try {
            await updateUserUsername(profileForm.username);
        } catch (error) {
            toast.error(error);
            throw new Error(error);
        }
    };

    const updateOtherUserData = async () => {
        try {
            await updateUserInfo({
                accountType: profileForm.accountType,
                timeZone: timezone.getLocalTZ().toLowerCase(),
                birthday: profileForm.birthday?.toISOString().slice(0, 10),
            });
        } catch (error) {
            report(error, "updateOtherUserData");
            throw new Error("Cannot update account type.");
        }
    };

    return (
        <FlexColumn style={{ gap: spacing.LG_2 }}>
            <FlexColumn>
                <h1 className="heading4">First, we need some info to get you set up</h1>
                <span className="body2">We’ll only ask for this information once.</span>
            </FlexColumn>

            <FlexColumn style={{ maxWidth: "36.4rem", gap: spacing.XS }}>
                <span className="bodyBold2">What’s your birthday?</span>
                <BorderedInputDatePicker
                    placeholder="Pick your date of birth"
                    disabled={isUnder13}
                    value={profileForm?.birthday}
                    DatePickerProps={{
                        disableFuture: true,
                    }}
                    onChange={newDate => updateProfileForm({ birthday: newDate.toDate() })}
                />
            </FlexColumn>

            <FlexColumn style={{ maxWidth: "36.4rem", gap: spacing.XS }}>
                <span className="bodyBold2">Are you a student or a teacher?</span>
                <Select
                    disabled={isUnderAge(profileForm.birthday, 18)}
                    options={[
                        { label: "Student", value: "Student" },
                        { label: "Teacher", value: "Teacher" },
                    ]}
                    onChange={({ value }) => updateProfileForm({ accountType: value })}
                    selected={{ label: profileForm.accountType, value: profileForm.accountType }}
                    menuSx={{ width: "36.5rem", padding: "1rem" }}
                    btnSx={{
                        justifyContent: "space-between",
                        height: "4.8rem",
                        borderRadius: 999,
                        backgroundColor: isUnderAge(profileForm.birthday, 18)
                            ? themeColors.neutral1
                            : themeColors.neutralWhite,
                        border: `1.5px solid ${themeColors.neutralBlack}`,
                    }}
                />
            </FlexColumn>

            {/* TODO: check icon */}
            <FlexColumn style={{ gap: "0.6rem" }}>
                <span className="bodyBold2">Set up your username!</span>
                <p className="secondaryText1">
                    You can only change your username one time after this for free, so choose wisely! If you see a red
                    circle with an X that means that username is already taken.{" "}
                </p>
                <FlexRowAlignCenter style={{ gap: spacing.XS_2 }}>
                    <RoundInput
                        disabled={userNameDisabled}
                        placeholder="Enter a username"
                        value={profileForm.username}
                        onChange={e => updateProfileForm({ username: e.target.value })}
                        sx={{
                            padding: spacing.SM,
                            border: "unset",
                            ...((!isUsernameAvailable && !userNameDisabled) || profileForm.username?.indexOf(" ") >= 0
                                ? { borderWidth: "1.5px", borderColor: "#f18888" }
                                : { border: "unset" }),
                        }}
                    />
                    <CircularOutlineIcon
                        isDisabled
                        radius={"3rem"}
                        style={{
                            backgroundColor: isUsernameAvailable ? themeColors.primary : themeColors.errorPrimary,
                        }}
                        size={iconSizes.SM}
                        color={themeColors.pureWhite}
                        Icon={isUsernameAvailable ? Check : Ban}
                    />
                </FlexRowAlignCenter>
            </FlexColumn>

            <Flex style={{ justifyContent: "flex-end" }}>
                <CircularRectTextButton
                    style={{
                        padding: `${spacing.XS_2} ${spacing.MD}`,
                        backgroundColor: themeColors.primary,
                    }}
                    onClick={handleSubmit}>
                    <span className="bodyBold2">next</span>
                </CircularRectTextButton>
            </Flex>
        </FlexColumn>
    );
};

export default BasicUserInfoForm;
