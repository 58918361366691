"use client";

import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { SubscriptionType } from "@knowt/syncing/graphql/schema";
import Image from "next/image";
import { toast } from "react-hot-toast";
import Mixpanel from "@/utils/analytics/Mixpanel";
import Link from "@/components/wrappers/Link";
import CircularRectTextButton, {
    CircularRectTextButtonProps,
} from "@/components/CircularButton/styled/CircularRectTextButton";
import { ASSETS_URL } from "@/config/deployConstants";
import { themeColors } from "@/utils/themeColors";

export const SubscribeRectangularBtn = ({ sx, children, ...btnProps }: CircularRectTextButtonProps) => {
    return (
        <CircularRectTextButton
            sx={{
                backgroundColor: "#FFCD1F",
                color: themeColors.pureWhite,
                borderRadius: "5rem",
                fontWeight: "600",
                fontSize: "1.43rem",
                padding: "1.1rem 1rem",
                ...sx,
            }}
            {...btnProps}>
            {children}
        </CircularRectTextButton>
    );
};

export const UpgradeButton = ({ subscriptionType }) => (
    <Link href={"/plans"} onClick={() => Mixpanel.track("Navigation Bar - Upgrade Clicked")}>
        <SubscribeRectangularBtn sx={{ width: "14rem", height: "3.8rem", color: themeColors.pureWhite }}>
            {!subscriptionType || subscriptionType === SubscriptionType.BASIC ? "Remove Ads" : "Upgrade"}
        </SubscribeRectangularBtn>
    </Link>
);

export const ResubscribeButton = () => {
    const { resubscribe } = useCurrentUser();

    return (
        <SubscribeRectangularBtn
            sx={{ width: "16rem", height: "3.8rem" }}
            onClick={async () => {
                await toast.promise(resubscribe(), {
                    loading: "Restarting your subscription...",
                    success: "Success!",
                    error: "Please try again later.",
                });
            }}>
            Resubscribe
            <Image
                src={`${ASSETS_URL}/images/raised_hands.png`}
                alt={"hands"}
                style={{ marginBottom: "0.3rem", objectFit: "cover" }}
                height={32}
                width={32}
            />
        </SubscribeRectangularBtn>
    );
};
