"use client";

import Dialog from "@mui/material/Dialog";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker, StaticDatePickerProps } from "@mui/x-date-pickers/StaticDatePicker";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import dayjs from "dayjs";
import { useBreakPoints } from "@/hooks/styles/useBreakpoints";
import { useTheme } from "@/utils/theme";
import { themeColors } from "@/utils/themeColors";
import { ModalProps } from "@mui/material";

type DateInputProps = {
    defaultValue: dayjs.Dayjs;
    isOpen: boolean;
    onClose: ModalProps["onClose"];
} & StaticDatePickerProps<dayjs.Dayjs>;

/**
 * Headless Date Picker. This component is only the picker with no `input` being bounded so we have full control on how we want
 * to style the input when it's combined
 * This has interesting props like:
 * - `disablePast`
 * - `disableFuture`
 * etc. https://mui.com/x/api/date-pickers/static-date-picker/#props
 */
const DatePicker = ({ sx, defaultValue, isOpen, onClose, ...staticDatePickerProps }: DateInputProps) => {
    const { smDown } = useBreakPoints();
    const { isDarkMode } = useTheme();
    const mode = isDarkMode ? "dark" : "light";

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Dialog
                open={isOpen}
                onClose={(e, reason) => {
                    if (reason === "backdropClick") return;
                    onClose?.(e, reason);
                }}>
                <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <ThemeProvider
                        theme={createTheme({
                            typography: { htmlFontSize: 10 },
                            palette: {
                                mode,
                                ...(mode === "light"
                                    ? { text: { primary: "#092522", secondary: "#e7e7e7" } }
                                    : { text: { primary: "#e7e7e7", secondary: "#4f4f4f" } }),
                            },
                        })}>
                        <StaticDatePicker
                            {...staticDatePickerProps}
                            onClose={onClose}
                            orientation={smDown ? "portrait" : "landscape"}
                            defaultValue={defaultValue}
                            sx={{ fontSize: "3rem", color: themeColors.neutralBlack, ...sx }}
                        />
                    </ThemeProvider>
                </div>
            </Dialog>
        </LocalizationProvider>
    );
};

export default DatePicker;
