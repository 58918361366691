import { useSearchParams } from "next/navigation";
import { useEffect } from "react";
import toast from "react-hot-toast";

export const MaybeShowError = () => {
    const searchParams = useSearchParams();

    useEffect(() => {
        const err = searchParams.get("err");
        if (err) {
            setTimeout(() => toast.error(decodeURIComponent(err)), 1000);
        }
    }, []);

    return null;
};
