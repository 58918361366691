"use client";
import { cleanEmail, federateSignIn, signIn } from "@knowt/syncing/hooks/user/auth";
import { TEMP_CACHED_SIGN_IN_SOURCE_KEY } from "@knowt/syncing/hooks/user/types";
import {
    validateAccountType,
    validateBirthdayByPlainBirthdayString,
    validateUsername,
} from "@knowt/syncing/hooks/user/userVerificationUtils";
import { updateUserInfo, updateUserUsername } from "@knowt/syncing/hooks/user/utils";
import { report } from "@/utils/analytics/logging";
import LocalKeyValueStore from "@/utils/LocalKeyValueStore";
import { ReadonlyURLSearchParams } from "next/navigation";
import { setRedirectAfterLogin } from "@/app/page/rootComponents/AuthHubListener";
import { AuthEvent } from "./AuthPopup/utils/constants";
import { OfferTypes } from "../PlansPages";
import { safeParse } from "@knowt/syncing/utils/stringUtils";
import { UserDetails } from "@knowt/syncing/graphql/schema";
import { now } from "@knowt/syncing/utils/SyncUtils";
import { TIME_SECONDS } from "@knowt/syncing/utils/dateTimeUtils";

export const SS_REDIRECT_KEY = "newUserPageRedirect";
export const SS_PARAMS_KEY = "newUserPageParams";
export const SS_OFFERS_PROMO = (offer: OfferTypes) => `${offer}PromoCode`;
export const SS_ADDITIONAL_IDENTITY_KEY = "additionalIdentityObj";
export const SS_MANUALLY_VERIFY_SIGNIN_KEY = "manuallyVerifySignin";

export const socialSignIn = async ({
    provider,
    event,
    pathname,
    searchParams,
}: {
    provider: string;
    event: AuthEvent;
    pathname: string;
    searchParams: ReadonlyURLSearchParams;
}) => {
    try {
        setRedirectAfterLogin(pathname, searchParams);
        await LocalKeyValueStore.setWithExpiry(TEMP_CACHED_SIGN_IN_SOURCE_KEY, event);
        await federateSignIn(provider);
    } catch (err) {
        window.location = `${window.location.origin}/retrySignin?provider=${provider}`;
    }
};

/**
 * If `SS_ADDITIONAL_IDENTITY_KEY` saved {username, birthday, accountType, Name} information, then update the user info
 */
export const updateUserAdditionalInfoIfExist = async () => {
    try {
        const { username, birthday, accountType, name } = JSON.parse(
            window.sessionStorage.getItem(SS_ADDITIONAL_IDENTITY_KEY)
        );

        validateUserAdditionalIdentity({ username, birthday, accountType });

        await updateUserUsername(username);

        return await updateUserInfo({ birthday, accountType, Name: name });
    } catch (err) {
        report(err, "updateUserAdditionalInfoIfExist");
        throw err;
    } finally {
        window.sessionStorage.removeItem(SS_ADDITIONAL_IDENTITY_KEY);
    }
};

export const maybeLogReferral = async (user: UserDetails) => {
    const params = safeParse(window.sessionStorage.getItem(SS_PARAMS_KEY));

    const ref_v2 = params?.ref_v2 ?? undefined;

    // if there is a referral, and the account is created within 24 hours, then track this referral
    if (ref_v2 && now() - Number(user.created) < TIME_SECONDS.DAY) {
        user = await updateUserInfo({ refFrom_v2: ref_v2 });
    }

    window.sessionStorage.removeItem(SS_PARAMS_KEY);
    return user;
};

export const validateUserAdditionalIdentity = ({ username, birthday, accountType }) => {
    validateBirthdayByPlainBirthdayString(birthday);
    validateUsername(username);
    validateAccountType(accountType);
};

export const authenticateUserCredentials = async ({ email, password }: { email: string; password: string }) => {
    const cleanedEmail = cleanEmail(email);

    return await signIn(cleanedEmail, password);
};
