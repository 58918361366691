// eslint-disable-next-line no-restricted-imports
import { SxProps } from "@mui/material";
import { LucideIcon } from "lucide-react";
import CircularOutlineIcon from "./CircularOutlineIcon";
import CircularRectTextButton from "./CircularRectTextButton";
import { themeColors } from "@/utils/themeColors";
import { iconSizes, strokeWidth } from "@/utils/iconProps";

export const FilledIconTextButton = ({
    Icon,
    fontSize = "1.4rem",
    width = "auto",
    padding = "1rem 0.8rem",
    height = "5rem",
    buttonColor = themeColors.primary,
    color = themeColors.primaryDark,
    text,
    onClick,
    tooltip,
    sx,
}: {
    Icon: LucideIcon;
    text: string;
    fontSize?: string;
    padding?: string;
    width?: string;
    height?: string;
    buttonColor?: string;
    color?: string;
    onClick: (e: any) => void;
    tooltip?: string;
    sx?: SxProps;
}) => {
    return (
        <CircularRectTextButton
            sx={{
                background: themeColors.neutralWhite,
                color: themeColors.neutralBlack,
                fontSize,
                padding,
                width,
                height,
                "&:hover": {
                    background: buttonColor,
                    color: color,
                },
                "&:active": {
                    transform: "scale(0.97)",
                },
                transition: "all 0.2s ease",
                gap: "0.5rem",
                paddingRight: "1.5rem",
                ...sx,
            }}
            onClick={onClick}
            tooltip={tooltip}>
            <CircularOutlineIcon
                Icon={Icon}
                size={iconSizes.SM}
                strokeWidth={strokeWidth.bold}
                radius="3.4rem"
                buttonColor={buttonColor}
                color={color}
            />
            {text}
        </CircularRectTextButton>
    );
};

export default FilledIconTextButton;
