import Link, { LinkWrapperProps } from "./wrappers/Link";

type ConditionalLinkProps = LinkWrapperProps & {
    condition: boolean;
};

export const ConditionalLink = ({ condition, children, ...linkedWrapperProps }: ConditionalLinkProps) => (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{condition ? <Link {...linkedWrapperProps}>{children}</Link> : children}</>
);
