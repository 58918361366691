import intersection from "lodash/intersection";
import { AnyUserContent } from "@/types/common";
import { FlashcardSet, Folder, UserDetails } from "@/graphql/schema";
import { isFolder } from "./dataCleaning";

export const filterClassItems = <T extends AnyUserContent>({
    user,
    classId,
    items,
    inTrash,
    sectionId,
}: {
    user: UserDetails;
    classId: string;
    items: Record<string, T>;
    inTrash?: boolean;
    sectionId?: string | null | undefined;
}) => {
    const isOwner = user?.classes?.teacher?.includes(classId);
    const userSections = user?.classes?.enrolled?.find(course => course.classId === classId)?.sections;

    return Object.entries(items).filter(([, item]) => {
        return (
            !!item.trash === inTrash &&
            // make sure it's a member of the class, this can change from revalidations when moving files
            item.classId === classId &&
            // only show items that are not in a folder or are the root folders
            (!item.folderId || (isFolder(item) && !(item as Folder).parentId)) &&
            // 4 cases where an item is accessible:
            // Owner:
            // 1. the user is the owner, and there is no specified section
            // 2. the user is the owner, and the item is part of the specified section (or part of all)
            // Enrolled:
            // 3. the item is accessible to all sections (no sections specified)
            // 4. the user is enrolled in a section that the item is accessible to
            ((isOwner && !sectionId) ||
                (isOwner
                    ? !item.sections || item.sections.includes(sectionId)
                    : (!item.sections || intersection(item.sections, userSections).length > 0) &&
                      !(item as FlashcardSet).draft))
        );
    });
};
