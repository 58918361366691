"use client";

import { LocalUser } from "@knowt/syncing/hooks/user/types";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { SubscriptionType } from "@knowt/syncing/graphql/schema";
import Skeleton from "@mui/material/Skeleton";
import clsx from "clsx";
import dynamic from "next/dynamic";
import Image from "next/image";
import React, { Suspense, useEffect } from "react";
import HamburgerBtnWithHandler from "./components/HamburgerBtnWithHandler";
import { ResubscribeButton, UpgradeButton } from "./components/NavbarSubscriptionBtns";
import NavbarLink from "./components/NavbarLink";
import styles from "./navbar.module.css";
import Link from "@/components/wrappers/Link";
import ProfileCircleWithMenu from "@/components/ProfileCircleWithMenu";
import NavbarSearchbar from "./components/NavbarSearchBar";
import NotificationBtnWithHandler from "./components/NotificationBtnWithHandler";
import { NavbarSigninBtn, NavbarSignupBtn } from "./components/styled/NavbarAuthButtons";
import { FlexColumn, FlexColumnAlignJustifyCenter, FlexRowAlignCenter } from "@/components/Flex";
import { CreateNewContentButtonWithHandler } from "@/components/CreateNewContent/CreateNewContentButtonWithHandler";
import { ASSETS_URL } from "@/config/deployConstants";
import br from "@/styles/breakpoints.module.css";
import { maybeLogSource } from "@knowt/syncing/hooks/user/auth";
import { now } from "@knowt/syncing/utils/SyncUtils";
import { MaybeShowError } from "./MaybeShowError";
import { useCheckToDisplayInitialAccountSetupPage } from "../InitialSetupPage";
import { canShowAds } from "@knowt/syncing/hooks/user/subscriptions";

const NavBarBanner = dynamic(() => import("./components/NavBarBanner"));

const KnowtLogo = () => {
    return (
        <Link href={"/"} style={{ fontSize: "4rem" /* somehow `fontSize` make it center, lol */ }}>
            <Image
                className={br.lgDownDisplayNone}
                src={`${ASSETS_URL}/images/knowt-logo.png`}
                alt="knowt logo"
                width={"145"}
                height={"45"}
                style={{ paddingTop: "1rem", objectFit: "contain" }}
                priority={true}
            />
            <Image
                className={clsx(br.lgUpDisplayNone, br.smDownDisplayNone)}
                src={`${ASSETS_URL}/images/knowt-logo-mobile.png`}
                alt="knowt logo"
                width={"42"}
                height={"42"}
                style={{ paddingTop: "1rem", objectFit: "contain" }}
                priority={true}
            />
        </Link>
    );
};

type NavBarProps = {
    serverUser?: LocalUser;
    innerContainerStyle?: React.CSSProperties;
    outerContainerStyle?: React.CSSProperties;
    className?: string;
};

// TODO: leads to some issues in the middle widths, fix the responsiveness of this
const NavBarAuthSkeleton = () => {
    return (
        <FlexRowAlignCenter style={{ gap: "2rem" }}>
            <Skeleton animation="wave" width={"8rem"} height={30} />
            <Skeleton animation="wave" width={"14rem"} height={30} />
        </FlexRowAlignCenter>
    );
};

// If we make this a server component, it will always force dynamic rendering, which is not what we want
const NavBar = ({ serverUser, innerContainerStyle, outerContainerStyle, className }: NavBarProps) => {
    useCheckToDisplayInitialAccountSetupPage();

    const { user, organization, loginInProgress } = useCurrentUser({ fallbackData: serverUser });

    useEffect(() => {
        // only log the source if the user has been made within the last minute
        if (serverUser?.user && now() - (serverUser.user?.created || 0) < 120) {
            maybeLogSource(serverUser.user);
        }
    }, []);

    const isSubscriptionCancelling = !!user?.subscriptions?.[0].cancel_at_period_end;

    const renderLeftContent = () => (
        <FlexRowAlignCenter key="left-content" style={{ gap: "2rem", flex: 1 }}>
            <div className={br.mdUpDisplayNone}>
                <HamburgerBtnWithHandler />
            </div>

            {/* KNOWT TEXT/LOGO */}
            <KnowtLogo />

            {/* KNOWT NAVIGATION */}
            <FlexRowAlignCenter
                key={"navbar-links"}
                style={{ gap: "2rem", marginLeft: "1rem" }}
                className={br.mdDownDisplayNone}>
                <NavbarLink key="home" text="Home" href="/" />
                <NavbarLink key="explore" text="Explore" href="/explore" />
                <NavbarLink key="exams" text="Exams" href="/exams" />
                <NavbarLink key="blog" text="Blog" href="/blog" />
            </FlexRowAlignCenter>
            <div
                className={br.mdDownDisplayNone}
                style={{
                    flex: 1,
                    maxWidth: "60rem",
                }}
                key={"desktop-search-bar"}>
                <NavbarSearchbar id={"desktop-main-search-bar"} />
            </div>
        </FlexRowAlignCenter>
    );

    const renderRightContent = () => {
        const rightItems: React.ReactElement[] = [];
        // TODO: show a cleaner way to show the loading/maybe add animation
        if (loginInProgress) {
            rightItems.push(<NavBarAuthSkeleton key={"navbar-skeleton"} />);
        } else {
            if (user) {
                rightItems.push(
                    <CreateNewContentButtonWithHandler key="create-new-content-btn" onlyShowIcon />,
                    <div className={br.mdUpDisplayNone} key={"mobile-search-bar"}>
                        <NavbarSearchbar id={"mobile-main-search-bar"} />
                    </div>,
                    <NotificationBtnWithHandler key="notification-btn" />,
                    <FlexColumnAlignJustifyCenter key={"profile-circle"} className={"smDownDisplayNone"}>
                        <ProfileCircleWithMenu pictureUrl={user.pictureUrl} />
                    </FlexColumnAlignJustifyCenter>
                );
            } else {
                rightItems.push(
                    <CreateNewContentButtonWithHandler key="create-new-content-btn" onlyShowIcon />,
                    <NavbarSigninBtn key="signin-btn" />,
                    <NavbarSignupBtn key="signup-btn" />
                );
            }
        }

        if (canShowAds({ user, organization })) {
            if (!isSubscriptionCancelling && user?.subscriptionType !== SubscriptionType.LIMITLESS) {
                rightItems.push(
                    <div key={"upgrade-button"} className={styles.subscribeBtn}>
                        <UpgradeButton key="upgrade-button" subscriptionType={user?.subscriptionType} />
                    </div>
                );
            }
            if (isSubscriptionCancelling) {
                rightItems.push(
                    <div key={"resubscribe-button"} className={styles.subscribeBtn}>
                        <ResubscribeButton key="resubscribe-button" />
                    </div>
                );
            }
        }

        return (
            <FlexRowAlignCenter style={{ justifyContent: "flex-end", columnGap: "1.6rem" }}>
                {rightItems}
            </FlexRowAlignCenter>
        );
    };

    const renderContents = () => {
        return (
            <FlexRowAlignCenter
                key="inner-container"
                className={clsx(styles.navbarInnerContainer, styles.navbarBackgroundColor)}
                style={{
                    position: "relative",
                    justifyContent: "space-between",
                    gap: "2rem",
                    transition: "box-shadow ease 0.5s",
                    height: "var(--navbar-height)",
                    boxShadow: "0px 3px 25px 0px rgba(0, 0, 0, 0.02)",
                    ...innerContainerStyle,
                }}>
                {renderLeftContent()}
                {renderRightContent()}
            </FlexRowAlignCenter>
        );
    };

    return (
        <>
            <FlexColumn
                className={className}
                id={"navbar"}
                style={{ position: "sticky", top: 0, zIndex: "900", ...outerContainerStyle }}>
                {renderContents()}
                <div className={br.smDownDisplayNone}>
                    <NavBarBanner />
                </div>
            </FlexColumn>
            <Suspense>
                <MaybeShowError />
            </Suspense>
            {__DEV__ && <div style={{ zIndex: 99, position: "fixed", top: "6rem" }}>{user?.ID || "no user..."}</div>}
        </>
    );
};

export default NavBar;
