import LocalKeyValueStore from "@/utils/LocalKeyValueStore";
import { STORAGE_KEYS } from "@knowt/syncing/constants";
import { UserDetails } from "@knowt/syncing/graphql/schema";
import { isSomeBasicInfoMissing } from "@knowt/syncing/hooks/user/graphqlUtils";
import { createQueryStrings } from "@knowt/syncing/utils/genericUtils";

export const getInitialSetupUrl = async ({ user }: { user: UserDetails | null }) => {
    if (!user) return null;

    if (typeof localStorage !== "undefined") {
        const migKey = (await LocalKeyValueStore.getWithExpiry("migKey")) as string;
        const oldAccountUserId = (await LocalKeyValueStore.getWithExpiry("userId")) as string;

        if (migKey && oldAccountUserId) {
            return `/migrate-account`;
        }
    }

    const shouldSkipIntroPopups =
        typeof localStorage !== "undefined" &&
        (await LocalKeyValueStore.getWithExpiry(STORAGE_KEYS.SKIP_INTRO_POPUPS)) === "true";

    if (shouldSkipIntroPopups) return null;

    const isBasicUserInfoMissing = isSomeBasicInfoMissing(user);
    const isReferralMissing = user.referral === null || user.referral === undefined;

    const initialTab = isBasicUserInfoMissing ? undefined : "REFERRAL";

    if (!isBasicUserInfoMissing && !isReferralMissing && initialTab === "REFERRAL") {
        return null;
    }

    return `/initial-setup${createQueryStrings({
        initialTab,
    })}`;
};
