"use client";

import {
    maybeLogReferral,
    SS_OFFERS_PROMO,
    SS_PARAMS_KEY,
    SS_REDIRECT_KEY,
    updateUserAdditionalInfoIfExist,
    useAuthSlideContextSelector,
} from "@/features/Auth";
import { STORAGE_KEYS } from "@/config/constants";
import getStripe from "@/utils/stripe/stripeJS";
import { fetchPostJSON } from "@knowt/syncing/fetchFunctions/fetchWrappers";
import { fetchServerUserInfo } from "@knowt/syncing/hooks/user/auth";
import { PRICING_OPTIONS } from "@knowt/syncing/hooks/user/subscriptionConstants";
import { Media, UserDetails } from "@knowt/syncing/graphql/schema";
import { ReadonlyURLSearchParams, usePathname } from "next/navigation";
import React, { useCallback, useEffect } from "react";
import LocalKeyValueStore from "@/utils/LocalKeyValueStore";
import { callAddMediaS3UserTag } from "@/hooks/media/graphqlUtils";
import { genericUpdateMedias, waitForMediaEntryCreationOnUpload } from "@/hooks/media/utils";
import { createAndOpenNewNote } from "@/utils/navigation/notes";
import LazyLoaded from "@/components/LazyLoaded";
import { useRouter } from "next13-progressbar";
import dynamic from "next/dynamic";
import useCombinedState from "@/utils/hooks/useCombinedState";
import { Hub } from "aws-amplify/utils";
import toast from "react-hot-toast";
import { wait } from "@/utils/genericUtils";
import { OfferTypes } from "@/features/PlansPages";
import "aws-amplify/auth/enable-oauth-listener";
import {
    LandingPageNoteData,
    LandingPageQuizletImportLink,
    MediaUploadedData,
    MediaUploadedMetadata,
} from "@knowt/syncing/constants/storage";
import { getInitialSetupUrl } from "@/features/InitialSetupPage";
import { removeCookie } from "@/utils/ssr/nextCookieServerUtils";

const ImportFromQuizletPopupLandingPageFlow = dynamic(
    () => import("@/components/Popup/ImportFromQuizletPopup/ImportFromQuizletPopupLandingPageFlow")
);

const SummarizeVideoFlowPopup = dynamic(() => import("@/components/Popup/SummarizeVideoFlowPopup"));

export const redirectAfterLoginIfNeeded = () => {
    const redirectRoute = window.sessionStorage.getItem(SS_REDIRECT_KEY);

    if (redirectRoute) {
        window.location.replace(redirectRoute);
        window.sessionStorage.removeItem(SS_REDIRECT_KEY);
    } else if (window.location.pathname !== "/") {
        window.location.replace("/");
    }
};

export const setRedirectAfterLogin = (pathname: string, searchParams: ReadonlyURLSearchParams) => {
    if (
        [
            "/login",
            "/signup",
            "/verify",
            "/reset",
            "/goodbye",
            "/",
            "/ai-video-summarizer",
            "/ai-pdf-summarizer",
            "/ai-powerpoint-summarizer",
            "/ai-spreadsheet-summarizer",
            "/ai-notes",
        ].every(path => path !== pathname)
    ) {
        const fullPath = (pathname as string) + (searchParams ? "?" + searchParams.toString() : "");
        window.sessionStorage.setItem(SS_REDIRECT_KEY, fullPath);
        window.sessionStorage.setItem(SS_PARAMS_KEY, JSON.stringify(searchParams.entries()));
    } else {
        window.sessionStorage.setItem(SS_REDIRECT_KEY, "/");
        window.sessionStorage.setItem(SS_PARAMS_KEY, JSON.stringify(searchParams.entries()));
    }
};

export const AuthHubListener = () => {
    const router = useRouter();
    const pathname = usePathname();

    const [importFromQuizletFlowStates, updateImportFromQuizletFlowStates] = useCombinedState<{
        quizletUrl: string | null;
        isPopupOpen: boolean;
    }>({
        quizletUrl: null,
        isPopupOpen: false,
    });

    const [importFromVideoSummarizeFlowStates, updateImportFromVideoSummarizeFlowStates] = useCombinedState<{
        videoUrl: string | null;
        isPopupOpen: boolean;
    }>({
        videoUrl: null,
        isPopupOpen: false,
    });

    const closeAuthSlide = useAuthSlideContextSelector(state => state.closeAuthSlide);

    const createUnidaysCheckoutSession = useCallback(
        async (user: UserDetails | null | undefined, offerPromoCode: string, offerType: OfferTypes) => {
            const response = await fetchPostJSON("/api/checkout_sessions", {
                priceId: PRICING_OPTIONS.MONTHLY_LIMITLESS.priceId,
                email: user?.Email,
                // code param
                code: offerPromoCode,
                ...(offerType === OfferTypes.UNIDAYS && { offerType }),
            });

            if (response.statusCode === 500) {
                return;
            }

            const stripe = await getStripe();
            await stripe?.redirectToCheckout({ sessionId: response.data.id });
        },
        []
    );

    useEffect(
        () => {
            return Hub.listen("auth", async ({ payload: { event } }) => {
                if (event === "signedOut") {
                    // clear all relevant cookies.
                    removeCookie(STORAGE_KEYS.HOME_PAGE_FILES_TAB);
                    removeCookie(STORAGE_KEYS.HOME_PAGE_TAB);
                    removeCookie(STORAGE_KEYS.HOME_PAGE_SORT_AND_DIRECTION);
                }
                if (event !== "signedIn") return;

                try {
                    // used so that the user source gets logged
                    let { user } = await fetchServerUserInfo();
                    if (!user?.ID) return null;

                    user = await maybeLogReferral(user);

                    try {
                        user = await updateUserAdditionalInfoIfExist();
                    } catch (err) {
                        // ignore
                    }

                    const unidaysPromo = window.sessionStorage.getItem(SS_OFFERS_PROMO(OfferTypes.UNIDAYS));
                    const studentBeansPromo = window.sessionStorage.getItem(SS_OFFERS_PROMO(OfferTypes.STUDENT_BEANS));
                    if (unidaysPromo || studentBeansPromo) {
                        window.sessionStorage.removeItem(SS_OFFERS_PROMO(OfferTypes.UNIDAYS));
                        window.sessionStorage.removeItem(SS_OFFERS_PROMO(OfferTypes.STUDENT_BEANS));
                        await createUnidaysCheckoutSession(
                            user,
                            (unidaysPromo || studentBeansPromo) as string,
                            unidaysPromo ? OfferTypes.UNIDAYS : OfferTypes.STUDENT_BEANS
                        );
                    }

                    // TODO(types): strongly type these keys: mediaUploadedData, mediaUploadedMetadata, landingPageNoteData
                    const uploadedMediaData = (await LocalKeyValueStore.getWithExpiry(
                        STORAGE_KEYS.MEDIA_UPLOADED_DATA
                    )) as MediaUploadedData;

                    await LocalKeyValueStore.remove(STORAGE_KEYS.MEDIA_UPLOADED_DATA);
                    if (uploadedMediaData) {
                        toast.loading("Finishing your file upload...", {
                            id: "uploadingMedia",
                        });
                        const { mediaId, extension, bucket } = uploadedMediaData;
                        await callAddMediaS3UserTag({ mediaId: `${mediaId}.${extension}`, bucket });
                        await waitForMediaEntryCreationOnUpload({ mediaId });

                        const uploadedMediaMetadata = (await LocalKeyValueStore.getWithExpiry(
                            STORAGE_KEYS.MEDIA_UPLOADED_METADATA
                        )) as MediaUploadedMetadata;

                        await LocalKeyValueStore.remove(STORAGE_KEYS.MEDIA_UPLOADED_METADATA);
                        if (uploadedMediaMetadata) {
                            const { title, description, subject, topic, exam_v2, examUnit, examSection } =
                                uploadedMediaMetadata;

                            await genericUpdateMedias({
                                mediaIds: [mediaId],
                                userId: user.ID,
                                updatedFields: {
                                    title,
                                    description,
                                    subject: subject,
                                    topic: topic,
                                    exam_v2: exam_v2,
                                    examUnit: examUnit,
                                    examSection: examSection,
                                } as Media,
                            });
                        }

                        closeAuthSlide();
                        toast.success("Your file has been uploaded!", {
                            id: "uploadingMedia",
                        });
                        router.push(`/media/${mediaId}`);
                        return;
                    }

                    const landingPageNoteData = (await LocalKeyValueStore.getWithExpiry(
                        STORAGE_KEYS.LANDING_PAGE_NOTE_DATA
                    )) as LandingPageNoteData;

                    await LocalKeyValueStore.remove(STORAGE_KEYS.LANDING_PAGE_NOTE_DATA);
                    if (landingPageNoteData) {
                        const { title, content } = landingPageNoteData;
                        closeAuthSlide();
                        await createAndOpenNewNote({ title, content }, user, router, { autoCreateFlashcards: true });
                        return;
                    }

                    const landingPageQuizletImportLink = (await LocalKeyValueStore.getWithExpiry(
                        STORAGE_KEYS.LANDING_PAGE_QUIZLET_IMPORT_LINK
                    )) as LandingPageQuizletImportLink;

                    if (landingPageQuizletImportLink) {
                        const { quizletUrl } = landingPageQuizletImportLink;
                        closeAuthSlide();
                        updateImportFromQuizletFlowStates({ quizletUrl, isPopupOpen: true });

                        return;
                    }

                    const landingPageVideoSummarizeLink = (await LocalKeyValueStore.getWithExpiry(
                        STORAGE_KEYS.LANDING_PAGE_VIDEO_SUMMARIZE_LINK
                    )) as
                        | {
                              videoUrl: string;
                          }
                        | null
                        | undefined;

                    if (landingPageVideoSummarizeLink) {
                        const { videoUrl } = landingPageVideoSummarizeLink;
                        closeAuthSlide();
                        updateImportFromVideoSummarizeFlowStates({ videoUrl, isPopupOpen: true });

                        return;
                    }

                    const initialAccountSetupUrl = await getInitialSetupUrl({ user });

                    if (initialAccountSetupUrl) {
                        closeAuthSlide();
                        // return early, we will do the redirect after initial account setup
                        return window.location.replace(initialAccountSetupUrl);
                    }

                    redirectAfterLoginIfNeeded();
                    closeAuthSlide();
                } catch (error) {
                    // ignore - ideally we want to show some suitable toast to the user
                }
            });
        },
        // note that next13-progressbar's `router` is emitted from the deps array as it's not stable
        [
            router,
            pathname,
            closeAuthSlide,
            createUnidaysCheckoutSession,
            updateImportFromQuizletFlowStates,
            updateImportFromVideoSummarizeFlowStates,
        ]
    );

    useEffect(() => {
        // on legacy browsers, we don't have BroadcastChannel.
        // https://caniuse.com/?search=BroadcastChannel
        if (typeof BroadcastChannel === "undefined") return;

        const interTabsAuthChannel = new BroadcastChannel("inter-tabs-auth-channel");

        const interTabsAuthChannelMessageHandler = async ({ data: _event }) => {
            await wait(1000); // wait a bit for things to settle down
            window.location.reload();
        };

        interTabsAuthChannel.addEventListener("message", interTabsAuthChannelMessageHandler);

        const intraTabAuthChannelUnsubscribe = Hub.listen("auth", async ({ payload: { event } }) => {
            if (event === "signIn" || event === "signOut") {
                interTabsAuthChannel.postMessage(event);
            }
        });

        return () => {
            interTabsAuthChannel.removeEventListener("message", interTabsAuthChannelMessageHandler);
            interTabsAuthChannel.close();
            intraTabAuthChannelUnsubscribe();
        };
    }, []);

    return (
        <div>
            <LazyLoaded load={importFromQuizletFlowStates.isPopupOpen}>
                <ImportFromQuizletPopupLandingPageFlow
                    quizletUrl={importFromQuizletFlowStates.quizletUrl}
                    isOpen={importFromQuizletFlowStates.isPopupOpen}
                    onClose={async () => {
                        updateImportFromQuizletFlowStates({ isPopupOpen: false });
                        await LocalKeyValueStore.remove("landingPageQuizletImportLink");
                    }}
                />
            </LazyLoaded>
            <LazyLoaded load={importFromVideoSummarizeFlowStates.isPopupOpen}>
                <SummarizeVideoFlowPopup
                    videoUrl={importFromVideoSummarizeFlowStates.videoUrl}
                    isOpen={importFromVideoSummarizeFlowStates.isPopupOpen}
                    onClose={async () => {
                        updateImportFromVideoSummarizeFlowStates({ isPopupOpen: false });
                        await LocalKeyValueStore.remove("landingPageVideoSummarizeLink");
                    }}
                />
            </LazyLoaded>
        </div>
    );
};
