import { useCallback, useState } from "react";

export type SetPartialState<S> = (partialState: Partial<S> | ((prevState: S) => Partial<S>)) => void;

export const useCombinedState = <S extends { [key: string]: unknown }>(initialState: S | (() => S)) => {
    const [combinedState, setCombinedState] = useState(initialState);

    const setPartialState: SetPartialState<S> = useCallback(partialState => {
        if (typeof partialState === "function") {
            setCombinedState(prevState => ({ ...prevState, ...partialState(prevState) }));
        } else {
            setCombinedState(prevState => ({ ...prevState, ...partialState }));
        }
    }, []);

    return [combinedState, setPartialState] as const;
};

export default useCombinedState;
