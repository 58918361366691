import { objectWithout } from "@knowt/syncing/utils/dataCleaning";

const LS_WALKTHROUGH_KEY = "walkthrough_untaken";

/**
 * we have feature for walkthrough where if user goes to other page and come back, walkthrough should continue from where they left
 * The array value is the steps we want to track of. If it has one length that means we don't want to keep track of each step
 */
const WALKTHROUGH_LOOKUP = {
    BEGIN_CREATE: [0],
    CREATE_PDF: [0],
    CHOOSE_NOTE_TEMPLATE: [0],
    CHOOSE_AI_PROMPT: [0],
    EDIT_FLASHCARDS: [0],
    TAKE_NOTE_TEST: [0],
    MEDIA_UPLOAD_PROGRESS: [0],
    CHAT_WITH_KAI: [0],
    VIDEO_CHAT_AI: [0],
    VIDEO_ASK_KAI_CREATE_NOTE: [0],
    VIDEO_ASK_KAI_CREATE_FLASHCARDS: [0],
    VIDEO_CONTENT_TAB: [0],
    PDF_CHAT_AI: [0],
    PDF_ASK_KAI_CREATE_NOTE: [0],
    PDF_ASK_KAI_CREATE_FLASHCARDS: [0],
    PDF_CONTENT_TAB: [0],
    CLASS_CREATE_NEW: [0],
    CLASS_SWITCH_FILES_TAB: [0],
    CLASS_DASHBOARD_INTRO: [0, 1, 2, 3],
    CLASS_OWN_SET_INTRO: [0],
    CLASS_NOT_OWN_SET_INTRO: [0],
    SET_OVERVIEW_NON_CLASS: [0],
};

export type WALKTHROUGH_LOOKUP_KEYS = { [Key in keyof typeof WALKTHROUGH_LOOKUP]: string };
/** { BEGIN_CREATE: "BEGIN_CREATE", CREATE_PDF: "CREATE_PDF", CHOOSE_NOTE_TEMPLATE: "CHOOSE_NOTE_TEMPLATE", ... } */
export const WALKTHROUGH_LABELS: WALKTHROUGH_LOOKUP_KEYS = Object.keys(WALKTHROUGH_LOOKUP).reduce((acc, key) => {
    acc[key] = key;
    return acc;
}, {}) as WALKTHROUGH_LOOKUP_KEYS;

export const removeAllWalkthrough = () => {
    localStorage.removeItem(LS_WALKTHROUGH_KEY);
};

export const initializeAllWalkthrough = () => {
    localStorage.setItem(LS_WALKTHROUGH_KEY, JSON.stringify(WALKTHROUGH_LOOKUP));
};

export const getRemainingWalkthrough = (): typeof WALKTHROUGH_LOOKUP => {
    const rawWalkthroughBucket = localStorage.getItem(LS_WALKTHROUGH_KEY);
    const walkthroughBucket = rawWalkthroughBucket ? JSON.parse(rawWalkthroughBucket) : {};
    return walkthroughBucket;
};

export const checkLSHasWalkthroughTaken = ({ label }: { label: string }) => {
    const remainingWalkthrough = getRemainingWalkthrough();
    return !remainingWalkthrough[label];
};

export const removeLSWalkthroughTaken = ({ label }) => {
    const remainingWalkthrough = getRemainingWalkthrough();
    const updatedWalkthrough = objectWithout(remainingWalkthrough, label);
    localStorage.setItem(LS_WALKTHROUGH_KEY, JSON.stringify(updatedWalkthrough));
};

export const updateLSWalkthroughSteps = ({ label, steps }: { label: string; steps: number[] }) => {
    const remainingWalkthrough = getRemainingWalkthrough();
    const updatedWalkthrough = { ...remainingWalkthrough, [label]: steps };
    localStorage.setItem(LS_WALKTHROUGH_KEY, JSON.stringify(updatedWalkthrough));
};

export const getLSWalkthroughCurrStep = ({ label }: { label: string }): number => {
    const remainingWalkthrough = getRemainingWalkthrough();
    return remainingWalkthrough[label][0];
};
