"use client";

import { useCallback } from "react";
import { createContext, useContextSelector } from "@knowt/syncing/utils/use-context-selector";
import useCombinedState from "@knowt/syncing/utils/hooks/useCombinedState";
import noop from "@knowt/syncing/utils/noop";
import { AuthEvent } from "../../AuthPopup/utils/constants";

type ShowLoginPageProps = {
    showLoginPage?: boolean;
    extra?: { prefilledEmail?: string; onClose?: (e?: React.MouseEvent) => void };
};
type ShowSingupPageProps = {
    showSignupPage?: boolean;
    extra?: { source?: string | null; onClose?: (e?: React.MouseEvent) => void };
};
type OpenAuthSlideProps = { showResetPage?: boolean } & { showVerifyPage?: boolean } & ShowLoginPageProps &
    ShowSingupPageProps & { showEmailChangePage?: boolean } & { showUsernameChangePage?: boolean; event: AuthEvent };

type AuthSlideContextProps = {
    authSlideStates: { isOpen: boolean } & OpenAuthSlideProps;
    openAuthSlide: (props: OpenAuthSlideProps) => void;
    closeAuthSlide: () => void;
};

const AuthSlideContext = createContext<AuthSlideContextProps>({
    authSlideStates: { isOpen: false, event: AuthEvent.GENERAL_SIGNUP },
    openAuthSlide: noop,
    closeAuthSlide: noop,
});

export const AuthSlideContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [authSlideStates, updateAuthSlideStates] = useCombinedState<AuthSlideContextProps["authSlideStates"]>({
        isOpen: false,
        showLoginPage: false,
        showResetPage: false,
        showVerifyPage: false,
        showSignupPage: false,
        showEmailChangePage: false,
        showUsernameChangePage: false,
        event: AuthEvent.GENERAL_SIGNUP,
        extra: { prefilledEmail: "", onClose: noop },
    });

    const openAuthSlide = useCallback(
        (props: OpenAuthSlideProps) => {
            const resetStates = {
                showLoginPage: false,
                showSignupPage: false,
                showResetPage: false,
                showVerifyPage: false,
                showEmailChangePage: false,
                showUsernameChangePage: false,
            };

            if (props.showLoginPage) {
                updateAuthSlideStates({ isOpen: true, ...props, ...resetStates, showLoginPage: true });
            } else if (props.showSignupPage) {
                updateAuthSlideStates({ isOpen: true, ...props, ...resetStates, showSignupPage: true });
            } else if (props.showVerifyPage) {
                updateAuthSlideStates({ isOpen: true, ...resetStates, showVerifyPage: true });
            } else if (props.showResetPage) {
                updateAuthSlideStates({ isOpen: true, ...resetStates, showResetPage: true });
            } else if (props.showEmailChangePage) {
                updateAuthSlideStates({ isOpen: true, ...resetStates, showEmailChangePage: true });
            } else if (props.showUsernameChangePage) {
                updateAuthSlideStates({ isOpen: true, ...resetStates, showUsernameChangePage: true });
            }
        },
        [updateAuthSlideStates]
    );

    const closeAuthSlide = useCallback(() => {
        updateAuthSlideStates({ isOpen: false, extra: { prefilledEmail: "", onClose: noop } });
    }, [updateAuthSlideStates]);

    return (
        <AuthSlideContext.Provider value={{ openAuthSlide, closeAuthSlide, authSlideStates }}>
            {children}
        </AuthSlideContext.Provider>
    );
};

export const useAuthSlideContextSelector = <T,>(selector: (state: AuthSlideContextProps) => T) =>
    useContextSelector(AuthSlideContext, selector);
